<template>
    <main :class="{'h-100': loadPage}">
        <Header></Header>
        <PhaseForm v-if="phaseData" :payload="phaseData" @loading="loading()" @delete="deleteModal" :working="working" @formData="submitForm">
            <template #heading>
                <h2 class="u-font-500 mb0">View & Edit Phase</h2>
            </template>
        </PhaseForm>

        <teleport to="#modals">
            <transition name="fade">
                <Modal
                v-if="openModalDelete"
                class="modal"
                :closeButton="false"
                @close="openModalDelete = false"
                >
                <template #header>
                    <div class="flag">
                    <div class="flag__icon">
                        <img
                        src="@/assets/img/add-contract-type.svg"
                        alt="add-contract-type"
                        />
                    </div>
                    <div class="flag__content">
                        <h2 class="u-font-500 mb0">Delete Phase</h2>
                    </div>
                    </div>
                </template>
                <template #body>
                    <p class="mb0">Are you sure you want to delete <strong>- {{phaseData.name}} -</strong></p>
                </template>
                <template #footer>
                    <div class="o-flex modal-button-wrapper">
                        <a
                            href="#"
                            class="button button--secondary"
                            @click="openModalDelete = false"
                            >Cancel</a
                        >
                        <button type="button" :disabled="workingDelete" @click="deletePhase" class="button button--primary">Delete</button>
                    </div>
                </template>
                </Modal>
            </transition>
        </teleport>
    </main>
</template>
<script>
import Header from '@/components/Header/Header.vue';
import PhaseForm from '../project/PhaseForm.vue';
import { toast } from 'vue3-toastify';
import Modal from '../../components/Modal/Modal.vue';
import { getPhaseDetailsApi, updatePhaseApi, deletePhaseApi } from '../../api/projects'; 
// import { mapGetters } from 'vuex';

export default {
    name:"PhasesViewEdit",
    components: {
        Header,
        PhaseForm,
        Modal,
    }, 
    data() {
        return {
            openModalDelete:false,
            loadPage: true,
            working: false,
            workingDelete: false,
            phaseData: null,
            projectId: null,
            phaseId: null,
        }
    },
    // computed: {
    //     ...mapGetters('singleProject', ['getProjectId']),
    //     ...mapGetters('phases', ['getPhaseId']),
    // },
    methods: {

        deleteModal() {
            this.openModalDelete = true
        },  

        loading(status) {
            this.loadPage = status
        },

        // idsHandler() {
        //     if(this.getProjectId === null && this.getPhaseId === null) {
        //         this.$router.push({name: 'project', params: { id: this.getProjectId }})
        //     }else {
        //         this.getPhaseDetails()
        //     }
        // },

        async getPhaseDetails() {
            try {
                const { data } = await getPhaseDetailsApi(this.projectId, this.phaseId);
                this.phaseData = data
            } catch (e) {
                if(e.response.status !== 404) {
                    toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
                }
            }
        },
        async submitForm(payload) {
            this.working = true
            try {
                await updatePhaseApi(this.projectId, this.phaseId, payload)
                this.working = false
                await this.$router.push({name: 'project-view-edit', params: { id: this.projectId }})
                toast.success('Your phase is successfully edit', {"position": "top-center", autoClose: 2000});

            } catch (e) {
                toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
                setTimeout(() => {
                    this.working = false;
                }, "3000");
            }
        },

        async deletePhase() {
            this.workingDelete = true
            try {
                await deletePhaseApi(this.projectId, this.phaseId);
                this.workingDelete = false
                await this.$router.push({name: 'project-view-edit', params: { id: this.projectId }})
                toast.success('Your phase is successfully deleted', {"position": "top-center", autoClose: 2000});

            } catch (e) {
                toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
                this.openModalDelete = false;
                setTimeout(() => {
                    this.workingDelete = false;
                }, "3000");
            }
        },
    },
    created() {
        this.projectId = localStorage.getItem('projectId');
        this.phaseId = localStorage.getItem('phaseId');
        this.getPhaseDetails()
    }
}
</script>
<style lang="scss" scoped>
    .modal-button-wrapper {
        gap: 16px;

        .button {
            height: 44px;
        }
    }
</style>