<template>
    <main :class="{'h-100': loadPage}">
        <section class="filter-wrapper o-flex o-flex--center o-flex--justify o-flex--wrap gap-10">
            <div class="search-box">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                    d="M17 17L21 21M11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11C19 13.2053 18.1077 15.2022 16.6644 16.6493C15.2161 18.1015 13.213 19 11 19Z"
                    stroke="#637083"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    />
                </svg>
                <input
                    type="search"
                    class="input input--primary input--search"
                    placeholder="Search employees"
                    v-model="searchTerm" 
                    @input="debouncedOnSearch"
                />
            </div>
            <div class="filter-wrapper__btns o-flex o-flex--center">
                <div class="filter mr-" v-click-outside="dropdownClose">
                    <button @click="dropdown = true" class="button button--secondary" :class="{'active' : activeFilters}">
                        <svg width="20" height="20" class="mr-" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.50004 5.83333V3.5C2.50004 2.94772 2.94775 2.5 3.50004 2.5H16.5001C17.0524 2.5 17.5001 2.94766 17.5001 3.4999L17.5004 5.83333M2.50004 5.83333L7.98416 10.534C8.20581 10.724 8.33337 11.0013 8.33337 11.2933V16.2192C8.33337 16.8698 8.94476 17.3472 9.57591 17.1894L10.9092 16.856C11.3544 16.7447 11.6667 16.3448 11.6667 15.8859V11.2933C11.6667 11.0014 11.7943 10.724 12.0159 10.534L17.5004 5.83333M2.50004 5.83333H17.5004" stroke="#344051" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Filter
                    </button>
                    <transition name="fade">
                        <div class="filter__dropdown" v-if="dropdown">
                            <div class="filter__header o-flex o-flex--end o-flex--justify">
                                <div class="o-flex o-flex--center gap-10">
                                    <img src="../../assets/img/filter-dropdown.svg" alt="">
                                    <h2 class="u-font-500 mb0">Filter</h2>
                                </div>
                                <a href="javascript:;" @click="clearAll" v-if="activeFilters" class="clear">Reset All</a>
                            </div>
                            <div class="filter__body">
                                <h3>Skills</h3>
                                <div class="o-flex o-flex--center mb">
                                    <div class="check-box mr+">
                                        <input id="skills1" v-model="skill" type="radio" name="skills" :value="1">
                                        <label for="skills1">
                                            <div class="check-icon">
                                            <svg fill="none" width="16" viewBox="0 0 16 16">
                                                <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.333 8.667 6 11.333l6.667-6.666"/>
                                            </svg>
                                            </div>
                                            <p class="check-text">Match any</p>
                                        </label>
                                    </div>
                                    <div class="check-box">
                                        <input id="skills2" v-model="skill" type="radio" name="skills" :value="0">
                                        <label for="skills2">
                                            <div class="check-icon">
                                            <svg fill="none" width="16" viewBox="0 0 16 16">
                                                <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.333 8.667 6 11.333l6.667-6.666"/>
                                            </svg>
                                            </div>
                                            <p class="check-text">Match all</p>
                                        </label>
                                    </div>
                                </div>
                                <div class="relative">
                                    <VueMultiselect
                                    v-model="skillSelected"
                                    placeholder="Type..."
                                    :options="allSkills"
                                    :taggable="true"
                                    :multiple="true"
                                    label="name"
                                    :close-on-select="true"
                                    class="input--tags mb-"
                                    track-by="name"/>
                                    <div class="u-text-right">
                                        <a href="javascript:;" class="clear" @click="skillSelected = []">Clear</a>
                                    </div>
                                </div>
                                <div class="divider divider-1 mt- mb-"></div>
                                <h3>Seniority</h3>
                                <div class="relative">
                                    <VueMultiselect class="mb-" v-model="senioritySelected" placeholder="Type..." :options="allSeniorities" label="name" track-by="name">
                                        <template #noResult>
                                            <span>No results</span>
                                        </template>
                                    </VueMultiselect>
                                    <div class="u-text-right">
                                        <a href="javascript:;" class="clear" @click="senioritySelected = null">Clear</a>
                                    </div>
                                </div>
                                <div class="divider divider-1 mt- mb-"></div>
                                <h3>Position</h3>
                                <div class="o-flex o-flex--center mb">
                                    <div class="check-box mr+">
                                        <input 
                                            id="position1" 
                                            v-model="position" 
                                            type="radio" 
                                            name="positions" 
                                            :value="1" 
                                        />
                                        <label for="position1">
                                            <div class="check-icon">
                                            <svg fill="none" width="16" viewBox="0 0 16 16">
                                                <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.333 8.667 6 11.333l6.667-6.666"/>
                                            </svg>
                                            </div>
                                            <p class="check-text">Match any</p>
                                        </label>
                                    </div>
    
                                    <div class="check-box">
                                        <input 
                                            id="position2" 
                                            v-model="position" 
                                            type="radio" 
                                            name="positions" 
                                            :value="0" 
                                        />
                                        <label for="position2">
                                            <div class="check-icon">
                                            <svg fill="none" width="16" viewBox="0 0 16 16">
                                                <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.333 8.667 6 11.333l6.667-6.666"/>
                                            </svg>
                                            </div>
                                            <p class="check-text">Match all</p>
                                        </label>
                                    </div>
                                </div>
                                <div class="relative">
                                    <VueMultiselect
                                    v-model="positions"
                                    placeholder="Type..."
                                    :options="allPositions"
                                    :taggable="true"
                                    :multiple="true"
                                    label="name"
                                    :close-on-select="true"
                                    class="input--tags mb-"
                                    track-by="name"/>
                                    <div class="u-text-right">
                                        <a href="javascript:;" class="clear" @click="positions = []">Clear</a>
                                    </div>
                                </div>
                                <div class="divider divider-1 mt- mb-"></div>
                                <h3>Status</h3>
                                <div class="o-flex o-flex--center">
                                    <div class="check-box mr+">
                                        <input 
                                            id="status1" 
                                            v-model="status" 
                                            type="radio" 
                                            name="status" 
                                            value="" 
                                        />
                                        <label for="status1">
                                            <div class="check-icon">
                                            <svg fill="none" width="16" viewBox="0 0 16 16">
                                                <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.333 8.667 6 11.333l6.667-6.666"/>
                                            </svg>
                                            </div>
                                            <p class="check-text">All</p>
                                        </label>
                                    </div>
                                    <div class="check-box mr+">
                                        <input 
                                            id="status2" 
                                            v-model="status" 
                                            type="radio" 
                                            name="status" 
                                            value="active" 
                                        />
                                        <label for="status2">
                                            <div class="check-icon">
                                            <svg fill="none" width="16" viewBox="0 0 16 16">
                                                <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.333 8.667 6 11.333l6.667-6.666"/>
                                            </svg>
                                            </div>
                                            <p class="check-text">Active</p>
                                        </label>
                                    </div>
                                    <div class="check-box">
                                        <input 
                                            id="status3" 
                                            v-model="status" 
                                            type="radio" 
                                            name="status" 
                                            value="inactive" 
                                        />
                                        <label for="status3">
                                            <div class="check-icon">
                                            <svg fill="none" width="16" viewBox="0 0 16 16">
                                                <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.333 8.667 6 11.333l6.667-6.666"/>
                                            </svg>
                                            </div>
                                            <p class="check-text">Inactive</p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="filter__footer o-flex o-flex--justify-center gap-10">
                                <button type="button" class="button button--secondary" @click="dropdownClose">Cancel</button>
                                <button type="button" :disabled="filterWorking" @click="applyFilter" class="button button--primary">Apply</button>
                            </div>
                        </div>
                    </transition>
                </div>
        
                <router-link
                    :to="{name: 'addEmployee'}"
                    class="button button--primary">
                    <svg fill="none" width="20" viewBox="0 0 20 20">
                        <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5 10h5m5 0h-5m0 0V5m0 5v5"/>
                    </svg>
                    Add new
                </router-link>
            </div>
        </section>
    
        <div class="card">
            <div class="card__body" :class="{'h-100': loadPage}">
                <NoData v-if="!loadPage && data.length === 0"/>
                <Loader v-if="loadPage"/>
                <div v-if="!loadPage && data.length > 0" class="table-responsive">
                    <table class="table">
                        <tbody>
                            <tr v-for="item in data" :key="item.id" :class="{'inactive': item.status !== 'active'}">
                                <td width="34%">
                                    <div class="flag">
                                        <div class="flag-img" >
                                            <AuthorizedImg v-if="item?.file?.source?.original" class="loadedImg" :src="item?.file?.source?.original" />
                                            <svg v-if="!imageLoadingStatus || !item?.file" width="30" viewBox="0 0 448 512">
                                                <path fill="#414E62" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"/>
                                            </svg>
                                        </div>
                                        <div class="flag-content">
                                            <div class="flag-info o-flex o-flex--center o-flex--justify">
                                                <router-link :to="{ name: 'employee-view-edit', params: { id: item.id } }">{{item?.first_name}} {{item?.last_name}}</router-link>
                                                <div class="seniority-box" :class="[`seniority-box-${color(item?.seniority_level ? item?.seniority_level?.name : '')}`]">
                                                    {{ item?.seniority_level ? item.seniority_level.name : 'No Seniority' }}
                                                </div>
                                            </div>
                                            <div class="o-flex">
                                                <p class="mb0">Positions:</p>   
                                                <ul class="mb0 position-list">
                                                    <li v-for="position in item.positions" :key="position?.id">{{ position?.name || 'No Position' }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="skills-wrapper">
                                        <p class="mb0">Skills:</p>
                                        <ul class="mb0 skill-list">
                                            <li v-for="skill in item.skills" :key="skill.id" :class="{'inactive': skill.status !== 'active'}">
                                                {{ skill?.name || 'No Name' }}
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card__footer" v-if="!loadPage && data.length > 0">
                <Pagination @page-click="getEmployee" :info="pagination" />
            </div>
        </div>
    </main>
</template>
<script>
import VueMultiselect from 'vue-multiselect'
import Pagination from "../../components/Pagination/Pagination.vue";
import { getPaginationPayload } from "../../service/general";
import { getResourcesApi } from '../../api/employee/index';
import { toast } from 'vue3-toastify';
import Loader from '../../components/Loader/Loader.vue';
import { getPositions, getSkills, getSeniority } from '../../service/general';
import AuthorizedImg from '../../components/AuthorizedImg/AuthorizedImg.vue';
import ImageLoading from '../../components/ImageLoading/ImageLoading.vue';
import NoData from '@/components/NoData/NoData.vue';

export default {
    name:"EmployeeDashboard",
    components: {
        VueMultiselect,
        Pagination,
        Loader,
        AuthorizedImg,
        ImageLoading,
        NoData,
    },
    data() {
        return {
            imageLoadingStatus: true,
            filterWorking:false,
            dropdown: false,
            loadPage: true,
            pagination: null,
            searchTerm: '',
            skill: 1,
            position: 1,
            status: '',
            skillSelected: [],
            senioritySelected: null,
            allSeniorities: [],
            roleId: null,
            statusId: null,
            allSkills: [],
            positions: [],
            allPositions:[],
            data:[],
        }
    },
    computed: {
        activeFilters() {
            return (
                this.skill === 0 ||
                this.position === 0 ||
                this.skillSelected.length > 0 || 
                this.senioritySelected !== null ||
                this.positions.length > 0 ||  
                this.status !== '' 
            );
        }
    },
    methods: {

        applyFilter() {
            this.dropdown = false;
            if (this.skill === 0 &&
                this.position === 0 &&
                this.status !== '' &&
                this.skillSelected.length > 0 && 
                this.senioritySelected && 
                this.positions.length > 0) {
                localStorage.removeItem('filter');
            } else {
                const filterData = {
                    skillCheck: this.skill || 0,
                    positionCheck: this.position || 0,
                    status: this.status || '',
                    skills: this.skillSelected || [], 
                    seniority: this.senioritySelected || null,
                    positions: this.positions || [],
                };
                localStorage.setItem('filter', JSON.stringify(filterData));
            }
            this.getEmployee();
        },
        clearAll() {
            this.skill = 1;
            this.position = 1;
            this.skillSelected = [];
            this.senioritySelected = null;
            this.positions = [];
            this.status = '';
            localStorage.removeItem('filter'); 
            this.getEmployee();
        },  
        dropdownClose() {

            if(this.dropdown) {
                this.dropdown = false
            }
        },
        color(seniority) {
            if (seniority === 'Senior') {
                return 'senior';
            } else if (seniority === 'Medior') {
                return 'medior';
            } else if (seniority === 'Junior') {
                return 'junior';
            } else {
                return 'junior';
            }
        },
        async getEmployee(page = 1) {
            this.filterWorking = true;
            const filterData = localStorage.getItem('filter');
            let filter = {};

            try {
                filter = filterData ? JSON.parse(filterData) : {};
            } catch (e) {
                toast.error("Failed to parse filter data:", { position: "top-center", autoClose: 2000 });
                filter = {};
            }

            const skills = (filter.skills || []).map(item => item.id);
            const positions = (filter.positions || []).map(item => item.id);

            let payload;

            if (Object.keys(filter).length > 0) {
                this.skillSelected = filter.skills || [];
                this.positions = filter.positions || [];
                this.senioritySelected = filter.seniority || null;
                this.status = filter.status || '';
                this.skill = filter.skillCheck || 0;
                this.position = filter.positionCheck || 0;

                payload = {
                    page,
                    ...(this.searchTerm && { term: this.searchTerm }),
                    ...(skills.length ? { skill_ids: skills } : {}),
                    ...(positions.length ? { position_ids: positions } : {}),
                    ...(this.senioritySelected && this.senioritySelected.id ? { seniority_level_id: this.senioritySelected.id } : {}),
                    skill_any: this.skill,
                    position_any: this.position,
                    status: this.status,
                };
            } else {
                payload = {
                    page: page,
                    ...(this.searchTerm && { term: this.searchTerm }),
                    ...(skills.length ? { skill_ids: skills } : {}),
                    ...(positions.length ? { position_ids: positions } : {}), 
                    ...(this.senioritySelected && this.senioritySelected.id ? { seniority_level_id: this.senioritySelected.id } : {}), 
                    skill_any: this.skill,
                    position_any: this.position,
                    status: this.status,
                    ...(this.skill !== 1 ? { skill_any: this.skill } : 1), 
                    ...(this.position !== 1 ? { position_any: this.position } : {}),
                    ...(this.status !== '' ? { status: this.status } : {}),
                };
            }

            try {
                
                this.loadPage = true;
                const queryParams = new URLSearchParams();
                Object.keys(payload).forEach(key => {
                    if (Array.isArray(payload[key])) {
        
                        payload[key].forEach(value => {
                            queryParams.append(`${key}[]`, value);
                        });
                    } else if (payload[key] !== undefined && payload[key] !== null) {
                        queryParams.append(key, payload[key]); 
                    }
                });

                const {data} = await getResourcesApi(queryParams.toString());
                const [allPositions, allSeniorities, allSkills]  = await Promise.all([getPositions(), getSeniority(), getSkills()])
                this.allPositions = allPositions;
                this.allSeniorities = allSeniorities;
                this.allSkills = allSkills;
                this.data = data.data;
                this.pagination = getPaginationPayload(data); 
                this.loadPage = false; 
                this.filterWorking = false;

            } catch (e) {
                this.loadPage = false;
                if (e.response && e.response?.status !== 401) {
                    toast.error(e.response?.data.message ?? e.message, { "position": "top-center", autoClose: 2000 });
                }
            }
        },
        onSearch() {
            this.getEmployee();
        },

        debouncedOnSearch() {
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
            this.onSearch();
            }, 500);
        }
    },
    mounted() {
        this.getEmployee();
    },
}
</script>
<style lang="scss" scoped>

    .clear {
        color: #0166FF;
        font-size: 14px;
    }

    .card {
        padding: 16px 24px;

        @media(max-width: 1200px) {
            padding: 16px;
        }
    }

    .table {
        td {
            position: relative;
            vertical-align: top;
            padding: 16px 32px 16px 0;
            // padding-right: 32px;

            @media(max-width: 1400px) {
                width: 50%;
                padding: 16px 16px 16px 0;
            }

            @media(max-width: 768px) {
                display: block;
                width: 100%;
                border: none;
                padding: 0;
            }

            &:before {
                content: "";
                position: absolute;
                top: 20px;
                bottom: 20px;
                left: 0;
                width: 1px;
                background: var(--light-gray);

                @media(max-width: 768px) {
                    display: none;
                }
            }

            &:first-child:before {
                display: none;
            }

            &:last-child {
                padding-left: 32px;
                table-layout: fixed;

                @media(max-width: 1400px) {
                    padding-left: 16px;
                }

                @media(max-width: 768px) {
                    padding-left: 0;
                }
            }
        }

        tr {
            &.inactive {
                opacity: .4;
            }

            @media(max-width: 768px) {
                border-bottom: 1px solid var(--light-gray);
            }
        }

        tbody tr:first-child td {
            padding-top: 0;

            &:last-child:before {
                top: 4px;
            }
        }

        tbody tr:last-child td {
            border-bottom: 0;
        }
    }

    .filter-select {
        min-width: 290px;
    }

    .button--primary {
        white-space: nowrap;
        // width: auto;
        gap: 8px;
    }

    .flag {
        align-items: flex-start;
        padding: 13px 0;
    }

    .flag-img {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-color: #f2f4f7;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        @media(max-width: 1200px) {
            width: 60px;
            height: 60px;
        }

        .loadedImg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            min-width: 100%;
            min-height: 100%;
            max-height: 120%;
            max-width: 120%;
            object-fit: cover;
        }

        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .flag-content {
        // padding-top: 2px;
        flex: 1;
        position: relative;

        a {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            color: #0166FF;
            display: flex;
        }

        p {
            margin-right: 10px;
            font-size: 12px;
            color: #344051;
        }
    }

    .flag-info {
        margin-bottom: 8px;
        gap: 8px;
        flex-wrap: wrap;

        @media(max-width: 992px) {
            flex-direction: column;
            align-items: start;
        }
    }

    .seniority-box {
        // position: absolute;
        // right: 0;
        // top: 2px;
        height: 20px;
        padding: 0 10px;
        font-size: 12px;
        font-weight: 500;
        border-radius: 4px;
        display: flex;
        align-items: center;

        &-senior {
            background-color: #FFE5E5;
            color: #790C0C;
        }

        &-medior {
            background-color: #E5F2FF;
            color: #00254D;
        }

        &-junior {
            background-color: #F0F9FF;
            color: #0B4A6F;
        }
    }

    .skills-wrapper {
        display: flex;
        gap: 10px;
        position: relative;
        height: 100%;
        align-items: flex-start;
        padding: 13px 0;

        li {
            &.inactive {
                opacity: .5;
            }
        }
        
        p {
            font-size: 12px;
            padding-top: 2px;
            color: #344051;
        }
    }

    .position-list {
        li {
            &:not(:last-child) {
                &:after {
                    margin-right: 5px;
                    content:", ";
                }
            }
        }
    }

    .position-list, .skill-list {
        display: flex;
        flex-wrap: wrap;

        li {
            font-size: 12px;
            font-weight: 600;
        }
    }

    .skill-list {
        gap: 8px;

        li {
            height: 20px;
            padding: 0 10px;
            border: 1px solid #8FC4FF;
            border-radius: 4px;
            display: flex;
            align-items: center;
        }
    }

</style>