<template>
    <main :class="{'h-100': loadPage}">
        <header class="mb">
            <router-link
              active-class="active"
              class="nav__link"
              :to="{name: 'dashboard'}"
            >
                Team Utilization & Cost
            </router-link>
            <router-link
              active-class="active"
              class="nav__link"
              :to="{name: 'estimations-dashboard'}"
            >
                Estimations
            </router-link>
            <router-link
              active-class="active"
              class="nav__link"
              :to="{name: 'total-cost'}"
            >
                Total Cost of Unused Resources
            </router-link>
        </header>

        <div class="grid">
            <div class="card">
                <div class="card__header p0 ">
                    <h3 class="u-font-500 mb-">Estimations Won</h3>
                </div>
                <div class="card__body ">
                    <h2 class="u-font-600 mb0">$ 17,923.00</h2>
                </div>
            </div>
            <div class="card">
                <div class="card__header p0 ">
                    <h3 class="u-font-500 mb-">Estimations Lost</h3>
                </div>
                <div class="card__body ">
                    <h2 class="u-font-600 mb0">$ 5,743.00</h2>
                </div>
            </div>
        </div>

        <div class="card">
            <div v-if="!loadPage" class="card__header card--border o-flex o-flex--center o-flex--justify o-flex--wrap pt-">
                <div class="flag">
                    <div class="flag__icon">
                        <img src="@/assets/img/dashboard-chart.svg" alt="dashboard-chart" />
                    </div>
                    <div class="flag__content">
                        <h2 class="u-font-500 mb0">Estimations - Won vs Lost</h2>
                        <p class="mb0">Check your recent estimations</p>
                    </div>
                </div>
                <div class="search-box">
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        d="M17 17L21 21M11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11C19 13.2053 18.1077 15.2022 16.6644 16.6493C15.2161 18.1015 13.213 19 11 19Z"
                        stroke="#637083"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        />
                    </svg>
                    <input
                        type="search"
                        class="input input--primary input--search"
                        placeholder="Search"
                        v-model="searchTerm" 
                        @input="debouncedOnSearch"
                    />
                </div>
            </div>

            <div class="card__body" :class="{'h-100': loadPage}">
                <Loader v-if="loadPage"/>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Value</th>
                            <th>Employees</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Estimation 1
                                </td>
                                <td>
                                    12/12/2024
                                </td>
                                <td>
                                    Won
                                </td>
                                <td>
                                    $3,000.00
                                </td>
                                <td>
                                    <div class="o-flex o-flex--center gap-5">
                                        <StatusBox>
                                            Ivana Vojnic
                                        </StatusBox>
                                        <StatusBox>
                                            Marko Djakovic
                                        </StatusBox>
                                        <StatusBox>
                                            Jovan Poznanovic
                                        </StatusBox>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Estimation 1
                                </td>
                                <td>
                                    12/12/2024
                                </td>
                                <td>
                                    Won
                                </td>
                                <td>
                                    $3,000.00
                                </td>
                                <td>
                                    <div class="o-flex o-flex--center gap-5">
                                        <StatusBox>
                                            Ivana Vojnic
                                        </StatusBox>
                                        <StatusBox>
                                            Marko Djakovic
                                        </StatusBox>
                                        <StatusBox>
                                            Jovan Poznanovic
                                        </StatusBox>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Estimation 1
                                </td>
                                <td>
                                    12/12/2024
                                </td>
                                <td>
                                    Won
                                </td>
                                <td>
                                    $3,000.00
                                </td>
                                <td>
                                    <div class="o-flex o-flex--center gap-5">
                                        <StatusBox>
                                            Ivana Vojnic
                                        </StatusBox>
                                        <StatusBox>
                                            Marko Djakovic
                                        </StatusBox>
                                        <StatusBox>
                                            Jovan Poznanovic
                                        </StatusBox>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Estimation 1
                                </td>
                                <td>
                                    12/12/2024
                                </td>
                                <td>
                                    Won
                                </td>
                                <td>
                                    $3,000.00
                                </td>
                                <td>
                                    <div class="o-flex o-flex--center gap-5">
                                        <StatusBox>
                                            Ivana Vojnic
                                        </StatusBox>
                                        <StatusBox>
                                            Marko Djakovic
                                        </StatusBox>
                                        <StatusBox>
                                            Jovan Poznanovic
                                        </StatusBox>
                                    </div>
                                </td>
                            </tr>
                            <!-- <tr v-for="item in data" :key="item.id">
                                <td>
                                    {{ item.name }}
                                </td>
                                <td>
                                    <ul>
                                        <li v-for="phase in item.phases" :key="phase.id">
                                            {{ phase.name }}
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    {{ item.manager?.name }}
                                </td>
                                <td>
                                    
                                </td>
                                <td>
                                    <ul>
                                        <li v-for="phase in item.phases" :key="phase.id">
                                            <ul>
                                                <li v-for="skill in phase.details?.skills" :key="skill.id">
                                                    {{ skill.name }}
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul
                                        class="o-flex o-flex--center o-flex--justify-center action-list"
                                    >
                                        <li>
                                            <a href="javascript:;" class="icon-trigger">
                                                <SvgIcon  :icon="svgIcons.delete"
                                                    icon-width="20px"
                                                    icon-height="20px"/>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div> 

    </main>
</template>
<script>
import Loader from '@/components/Loader/Loader.vue';
import StatusBox from '@/components/StatusBox/StatusBox.vue';
import VueApexCharts from 'vue3-apexcharts';


export default {
    name: "Estimation",
    components: {Loader, VueApexCharts, StatusBox},
    data() {
        return {
            loadPage: false,
            searchTerm: '',
        }
    },
    methods: {
        debouncedOnSearch() {
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
            this.onSearch();
            }, 500);
        }
    }
}
</script>
<style lang="scss" scoped>
    header {
        display: flex;
        gap: 6px;

        .nav__link {
            padding: 0 16px;
            height: 32px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            color: #637083;

            &.exact-active {
                background-color: #fff;
                color: #141C25;
            }
        }
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
        margin-bottom: 16px;
    }

</style>