<template>
    <router-view />
</template>
<script>
export default {
    name: "DashboardPage",
}
</script>
<style lang="scss" scoped>

</style>