import axios from "axios";

export const getUtilizationResourceList = (params) => {
  const skillIds = params.skill_ids || [];
  delete params.skill_ids;

  const teamIds = params.team_ids || [];
  delete params.team_ids;

  let urlParams = new URLSearchParams(params).toString();

  if (urlParams) {
    urlParams = '?' + urlParams;

    skillIds.map((skillId) => {
      urlParams += '&skill_ids[]=' + skillId;
    });

    teamIds.map((teamId) => {
      urlParams += '&team_ids[]=' + teamId;
    });
  }

  return axios.get(`/api/admin/statistics/utilization-resource-list` + urlParams);
}
