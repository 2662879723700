import {getTeamleadsApi, getAllLocationsApi, getAllTeamsApi, getAllEmployeesApi, getAllSenioritiesApi, getAllSkillsApi, getTopSkillsApi, getAllPositionsApi, getAllContractTypesApi, getProjectManagersApi, getClientsApi, getAllProjectsApi} from '../api/general'

export const getPaginationPayload = data => {
    const {current_page, next_page_url, prev_page_url} = data

    return {
        current_page,
        next_page_url,
        prev_page_url
    }
}

export const getTeamleads = async () => {
    try {
        const { data } = await getTeamleadsApi();
        return data
    } catch (e) {
        return []
    }
}

export const getLocations = async() => {
    try {
        const { data } = await getAllLocationsApi();
        return data
    } catch (e) {
        return []
    }
}

export const getTeams = async() => {
    try {
        const { data } = await getAllTeamsApi();
        return data
    } catch (e) {
        return []
    }
}

export const getEmployees = async() => {
    try {
        const { data } = await getAllEmployeesApi();
        return data
    } catch (e) {
        return []
    }
}

export const getSeniority = async() => {
    try {
        const { data } = await getAllSenioritiesApi();
        return data
    } catch (e) {
        return []
    }
}

export const getSkills = async() => {
    try {
        const { data } = await getAllSkillsApi();
        return data
    } catch (e) {
        return []
    }
}

export const getTopSkills = async() => {
    try {
        const { data } = await getTopSkillsApi();
        return data
    } catch (e) {
        return []
    }
}



export const getPositions = async() => {
    try {
        const { data } = await getAllPositionsApi();
        return data
    } catch (e) {
        return []
    }
}

export const getContractTypes = async() => {
    try {
        const { data } = await getAllContractTypesApi();
        return data
    } catch (e) {
        return []
    }
}

export const getProjectManagers = async() => {
    try {
        const { data } = await getProjectManagersApi();
        return data
    } catch (e) {
        return []
    }
}

export const getClients = async() => {
    try {
        const { data } = await getClientsApi();
        return data
    } catch (e) {
        return []
    }
}

export const getAllProjects = async() => {
    try {
        const { data } = await getAllProjectsApi();
        return data
    } catch (e) {
        return []
    }
}


export const createFormData = (payload) => {
    const formData = new FormData();
    
    Object.keys(payload).forEach((key, index) => {
        if (payload[key] === null || payload[key] === undefined) {
        // skip
        } else if(Array.isArray(payload[key])) {
            for (let i = 0; i < payload[key].length; i++) {
                formData.append(`${key}[${i}]`, payload[key][i]);
            }
        } else {
            formData.append(key, payload[key]);
        };
    });

    return formData
}
