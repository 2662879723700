<template>
    <div class="timeline">
        <GanttElastic ref="gantt" :tasks="timelineTasks" :options="timelineOptions"></GanttElastic>
    </div>
</template>

<script>
import { GanttElastic } from "@/components/GanttElastic/GanttElastic.es";
import { dateYesterday, dateNow, dateFromString } from "@/service/DateTime";
import { createPlaceHolderTaskFromApiData } from "@/service/Timeline";
import "@/components/GanttElastic/style.css";

export default {
    name: 'Timeline',
    components: {
        GanttElastic,
    },
    props: {
        tasks: {
            type: Array,
            default: [],
        },
        dateFrom: {
            type: [Date, Object, String],
            default: dateYesterday(),
        },
        dateTo: {
            type: [Date, Object, String],
            default: dateNow(),
        },
        minHeight: {
            type: [Number, String],
            default: 500,
        },
        maxHeight: {
            type: [Number, String],
            default: 1000,
        },
    },
    data() {
        return {
            timelineTasks: [],
            timelineOptions: {
                maxRows: 50,
                minHeight: 550,
                maxHeight: 700,
                times: {
                    timeZoom: 20,
                },
                scope: {
                    before: 0,
                    after: 0,
                },
                chart: {
                },
                row: {
                    height: 24,
                },
                calendar: {
                    hour: {
                        display: false,
                    },
                    workingDays: [1, 2, 3, 4, 5],
                },
                taskList: {
                    // display: false,
                    columns: [
                        {
                            id: 1,
                            label: '',
                            value: 'description',
                            width: 200,
                            expander: true,
                        },
                    ]
                }
            }
        }
    },
    methods: {
    },
    watch: {
        tasks: {
            immediate: true,
            handler(newVal) {
              this.timelineTasks = newVal;
            },
        },
        // dateFrom: {
        //     immediate: true,
        //     handler(newVal) {
        //          if (typeof newVal === 'string') {
        //              this.dateFrom = dateFromString(newVal);
        //          }
        //     },
        // },
        // dateTo: {
        //     immediate: true,
        //     handler(newVal) {
        //         if (typeof newVal === 'string') {
        //            this.dateTo = dateFromString(dateTo);
        //         }
        //     },
        // },
    },
    created() {
        if (typeof this.dateFrom === 'string') {
            this.dateFrom = dateFromString(this.dateFrom);
        }
        if (typeof this.dateTo === 'string') {
            this.dateTo = dateFromString(this.dateTo);
        }

        if (typeof this.minHeight !== 'undefined') {
            this.timelineOptions.minHeight = Number(this.minHeight);
        }
        if (typeof this.maxHeight !== 'undefined') {
            this.timelineOptions.maxHeight = Number(this.maxHeight);
        }

        this.timelineTasks.push(createPlaceHolderTaskFromApiData(this.dateFrom, this.dateTo));
    }
}
</script>

<style lang="scss">
    .timeline {
        display: block;

        .gantt-elastic__main-container-wrapper {
            border-top: 1px solid var(--light-gray) !important;
            border-bottom: 1px solid var(--light-gray) !important;
        }

        // header
        .gantt-elastic__task-list-header,
        .gantt-elastic__chart-calendar-container {
            margin-bottom: 0 !important;
        }

        // .gantt-elastic__task-list-header {
        //     height: 52px !important;
        // }

        .gantt-elastic__chart-calendar-container {
            height: auto !important;
            border-right: 1px solid var(--light-gray) !important;
        }

        .gantt-elastic__chart-graph-container {
            border-right: 1px solid var(--light-gray) !important;
        }

        .gantt-elastic__calendar {
            background: var(--white) !important;
        }

        // month
        .gantt-elastic__calendar-row-text--month {
            font-size: 12px !important;
            line-height: 16px;
            font-weight: 600;
            color: var(--dark) !important;
        }

        .gantt-elastic__calendar-row--month {
            background: var(--light-gray1);
            border-bottom: 1px solid var(--light-gray);
        }

        .gantt-elastic__calendar-row-rect-child--month {
            // height: 28px !important;
            display: flex !important;
            align-items: center;
            padding: 2px 12px;
        }

        // day
        .gantt-elastic__calendar-row-rect-child--day {
            // height: 24px !important;
            display: flex !important;
            align-items: center;
            justify-content: center;
            padding: 1px;
            border-right: 1px solid var(--light-gray) !important;
            border-bottom: 1px solid var(--light-gray);
        }

        .gantt-elastic__calendar-row-text--day {
            font-size: 11px !important;
            line-height: 16px;
            font-weight: 500;
            white-space: nowrap;
            color: var(--gray) !important;
        }

        // grid
        .gantt-elastic__grid-line-horizontal {
            opacity: 0;
        }

        .gantt-elastic__grid-line-vertical {
            stroke: var(--light-gray) !important;
        }

        .gantt-elastic__grid-line-time {
            stroke: #0166FF !important;
        }

        .gantt-elastic__chart-days-highlight-rect {
            fill: rgba(225, 230, 239, 0.5) !important;
        }

        //list
        .gantt-elastic__task-list-header-column {
            border-left: 1px solid var(--light-gray) !important;

            &:last-of-type {
                border-right: 1px solid var(--light-gray) !important;
            }
        }

        .gantt-elastic__task-list-item {
            border-top: 1px solid var(--light-gray) !important;
            border-right: 1px solid var(--light-gray) !important;

            &:last-of-type {
                border-bottom: 1px solid var(--light-gray) !important;
            }
        }

        .gantt-elastic__task-list-item-column {
            border-left: 1px solid var(--light-gray) !important;
        }

        .gantt-elastic__task-list-item-value {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            color: var(--light-dark);
        }

        .gantt-elastic__chart-row-progress-bar-pattern {
            opacity: 0;
        }

        .gantt-elastic__chart-row-text-content {
            font-weight: 500;
            box-shadow: inset 0px 0px 0px 1px rgba(225, 230, 239, 0.8);
            border-radius: 10px;
        }

        .gantt-elastic__chart-row-text-content:has(div:empty) {
            padding: 0 !important;
        }
        
        // .gantt-elastic__chart-row-text {
        //     background-color: var(--project-color) !important;
        // }
    }

    .gantt-elastic__chart-row-text-wrapper {
        height: 16px!important;

        foreignObject {
            height: 16px!important;
            transform: translate(0, 10px)!important;
        }
    }

    .gantt-elastic__chart-row-task {
        border-radius: 15px;
        // transform: translate(0,-3px)!important;

    }


    .gantt-elastic__chart-row-text {
        // height: 20px!important;
        
        .gantt-elastic__chart-row-text-content--text {
            display: flex;
            align-items: center;
            line-height: 1!important;
        }
    }

</style>