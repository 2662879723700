<template>
    <transition name="fade">
        <Modal v-if="modalSkillOpen" class="modal modal--skills" :closeButton="true" @close="modalSkillOpen = false">
            <template #header>
                <div class="flag">
                    <div class="flag__icon">
                    <img src="@/assets/img/required-skills.svg" alt="required-skills" />
                    </div>
                    <div class="flag__content">
                        <h2 class="u-font-500 mb0">{{ modalSkillData.first_name }} {{ modalSkillData.last_name }}</h2>
                        <p class="mb0">Current skills</p>
                    </div>
                </div>
            </template>
            <template #body>
                 <div class="table-responsive">
                    <table class='table mb0'>
                        <thead>
                            <tr>
                                <th>Skills</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(skill, index) in modalSkillData.skills" :key="index">
                                <td>{{ skill.name }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
            <template #footer>
                <div class="o-flex modal-button-wrapper">
                    <a href="javascript:;" class="button button--secondary" @click="modalSkillOpen = false">Cancel</a>
                </div>
            </template>
        </Modal>
    </transition>
    <transition name="fade">
        <Modal v-if="modalBookingOpen" class="modal modal--bookings" :closeButton="true" @close="modalBookingOpen = false">
            <template #header>
                <div class="flag">
                    <div class="flag__icon">
                    <img src="@/assets/img/project.svg" alt="projects" />
                    </div>
                    <div class="flag__content">
                        <h2 class="u-font-500 mb0">{{ modalBookingData.first_name }} {{ modalBookingData.last_name }}</h2>
                        <p class="mb0">Current projects and utilization</p>
                    </div>
                </div>
            </template>
            <template #body>
                <div class="table-responsive">
                    <table class='table mb0'>
                        <thead>
                            <tr>
                                <th>Project</th>
                                <th>From / To</th>
                                <th>Utilization</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(booking, index) in modalBookingData.bookings" :key="index">
                                <td>{{ booking.project.name }} - {{ booking.phase.name }}</td>
                                <td>{{ modalFormatDateString(booking.start_date) }} {{ booking.ongoing || !booking.end_date ? '' : `- ${modalFormatDateString(booking.end_date)}` }}</td>
                                <td>{{ booking.hours_per_day }}h</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
            <template #footer>
                <div class="o-flex modal-button-wrapper">
                    <a href="javascript:;" class="button button--secondary" @click="modalBookingOpen = false">Cancel</a>
                </div>
            </template>
        </Modal>
    </transition>

    <div class="card">
        <div class="card__header o-flex o-flex--center o-flex--justify">
            <h2 class="u-font-600 mb0">Available Resources</h2>
            <a href="javascript:;" class="accordion-trigger" @click="accordionActive = !accordionActive" :class="{'active': accordionActive}">
                <svg fill="none" width="16" viewBox="0 0 16 16">
                    <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m4 10 4-4 4 4"/>
                </svg>
            </a>
        </div>
        <transition name="fade">
            <div v-if="accordionActive">
                <div class="card__body pt0 border-none">
                    <article v-for="(skill, skillIndex) in analysis.skills" :key="skillIndex" class="resources-accordion">
                        <div class="accordion-header o-flex o-flex--center">
                            <a href="javascript:;" class="accordion-table-trigger" @click="accordionTableActive = !accordionTableActive" :class="{'active': accordionTableActive}">
                                <svg fill="none" viewBox="0 0 20 20" width="20">
                                    <path stroke="#97A1AF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.17 7.08 12.08 10l-2.91 2.92m.83 5.41a8.33 8.33 0 1 0 0-16.66 8.33 8.33 0 0 0 0 16.66Z"/>
                                </svg>
                            </a>
                            <p class="mb0">{{ skill.name }}</p>
                            <div class="o-flex o-flex--center info-box-wrapper">
                                <InfoBox v-if="!allocation.skills[skillIndex].ongoing" :info="'primary'" :text="skill.hours + ' Hours Total'" />
                                <InfoBox v-if="!allocation.skills[skillIndex].ongoing" :info="allocationErrors.skills[skillIndex].fields.hours ? 'error' : (allocation.skills[skillIndex].hours == analysis.skills[skillIndex].hours ? 'secondary' : 'warning')" :text="allocation.skills[skillIndex].hours + ' Hours Allocated'" />
                                <span class="skill-info" v-if="allocationErrors.skills[skillIndex].message">{{ allocationErrors.skills[skillIndex].message }}</span>
                            </div>
                        </div>
                        <transition name="fade">
                            <div v-if="accordionTableActive" class="accordion-body">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Employee</th>
                                                <th>Availability</th>
                                                <th>View Skills</th>
                                                <th>Total Hours</th>
                                                <th>Hours <span>(per day)</span></th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>View Projects</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(resource, resourceIndex) in skill.resources" :key="resourceIndex">
                                                <td>{{ resource.first_name }} {{ resource.last_name }}</td>
                                                <td :class="{ 'error-field': allocationErrors.skills[skillIndex].resources[resourceIndex].fields.available_hours }">{{ resource.available_hours }} h</td>
                                                <td>
                                                    <a href="javascript:;" class="view-details" @click="openSkillsModal(resource)">
                                                        <svg fill="none" width="14" viewBox="0 0 14 10">
                                                            <path stroke="#0166FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7 6.333a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666Z"/>
                                                            <path stroke="#0166FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 5c-1.259 1.994-3.521 4-6 4S2.259 6.994 1 5c1.532-1.894 3.328-4 6-4s4.468 2.105 6 4Z"/>
                                                        </svg>
                                                        Details
                                                    </a>
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        v-model="allocation.skills[skillIndex].resources[resourceIndex].hours"
                                                        class="input input--primary"
                                                        :class="{ error: allocationErrors.skills[skillIndex].resources[resourceIndex].fields.hours }"
                                                        placeholder="Enter"
                                                        data-type="hours"
                                                        :data-skill="skill.id"
                                                        :data-resource="resource.id"
                                                        :disabled="isAllocationHoursInputDisabled(allocation.skills[skillIndex])"
                                                        @input="changeAllocationInput(allocationElement.hours, skill.id, resource.id)"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        v-model="allocation.skills[skillIndex].resources[resourceIndex].hours_per_day"
                                                        class="input input--primary"
                                                        :class="{ error: allocationErrors.skills[skillIndex].resources[resourceIndex].fields.hours_per_day }"
                                                        placeholder="0-8"
                                                        data-type="hours_per_day"
                                                        :data-skill="skill.id"
                                                        :data-resource="resource.id"
                                                        @input="changeAllocationInput(allocationElement.hours_per_day, skill.id, resource.id)"
                                                    />
                                                </td>
                                                <td :class="{ 'error-date-field': allocationErrors.skills[skillIndex].resources[resourceIndex].fields.start_date }">
                                                    <vue-date-picker
                                                        class="date-table error"
                                                        :enable-time-picker="false"
                                                        auto-apply
                                                        :teleport="true"
                                                        :model-type="'yyyy-MM-dd'"
                                                        :format="'dd.MM.yyyy'"
                                                        placeholder="Start"
                                                        v-model="allocation.skills[skillIndex].resources[resourceIndex].start_date"
                                                        :name="startDate"
                                                        :ui="{ input: 'date-picker' }"
                                                        :min-date="allocationStartDateMinDate(allocation.skills[skillIndex])"
                                                        :max-date="allocationStartDateMaxDate(allocation.skills[skillIndex])"
                                                        @date-update="changeAllocationDate(allocationElement.start_date, skill.id, resource.id)"
                                                    >
                                                        <template #input-icon>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3 10V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V10M3 10H21M3 10V6C3 4.89543 3.89543 4 5 4H7M21 10V6C21 4.89543 20.1046 4 19 4H18.5M15 4V2M15 4V6M15 4H10.5M7 6V2" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                        </template>
                                                    </vue-date-picker>
                                                </td>
                                                <td :class="{ 'error-date-field': allocationErrors.skills[skillIndex].resources[resourceIndex].fields.end_date }">
                                                    <vue-date-picker
                                                        class="date-table"
                                                        auto-apply
                                                        :teleport="true"
                                                        :enable-time-picker="false"
                                                        :model-type="'yyyy-MM-dd'"
                                                        :format="'dd.MM.yyyy'"
                                                        placeholder="End"
                                                        v-model="allocation.skills[skillIndex].resources[resourceIndex].end_date"
                                                        :name="endDate"
                                                        :ui="{ input: 'date-picker' }"
                                                        :min-date="allocationEndDateMinDate(allocation.skills[skillIndex])"
                                                        :max-date="allocationEndDateMaxDate(allocation.skills[skillIndex])"
                                                        :disabled="isAllocationEndDateDisabled(allocation.skills[skillIndex])"
                                                        @date-update="changeAllocationDate(allocationElement.end_date, skill.id, resource.id)"
                                                    >   
                                                        <template #input-icon>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3 10V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V10M3 10H21M3 10V6C3 4.89543 3.89543 4 5 4H7M21 10V6C21 4.89543 20.1046 4 19 4H18.5M15 4V2M15 4V6M15 4H10.5M7 6V2" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                        </template>
                                                    </vue-date-picker>
                                                </td>
                                                <td>
                                                    <a href="javascript:;" class="view-details" @click="openBookingModal(resource)">
                                                        <svg fill="none" width="14" viewBox="0 0 14 10">
                                                            <path stroke="#0166FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7 6.333a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666Z"/>
                                                            <path stroke="#0166FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 5c-1.259 1.994-3.521 4-6 4S2.259 6.994 1 5c1.532-1.894 3.328-4 6-4s4.468 2.105 6 4Z"/>
                                                        </svg>
                                                        Details
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </transition>
                    </article>
                </div>

                <div class="card__footer card--border o-flex o-flex--justify o-flex--row-reverse o-flex--wrap pb0">
                    <!-- <a href="#" class="button button--secondary" @click="openModalEdit = false">Cancel</a> -->
                    <button :disabled="allocationNumErrors > 0" @click="submitBooking" type="button" class="button button--primary">Save</button>
                    <!-- <button @click="submitBooking" type="button" class="button button--primary">Save</button> -->
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import VueMultiselect from 'vue-multiselect';
import VueDatePicker from '@vuepic/vue-datepicker';
import Modal from '@/components/Modal/Modal.vue';
import '@vuepic/vue-datepicker/dist/main.css';
import InfoBox from '@/components/InfoBox/InfoBox.vue';
import {
    getAllProjectsApi,
    getProjectPhaseAnalysisApi,
    getSingleBookingDateRangeCalc,
    bookProjectPhase,
} from '@/api/bookings';
import {
    validateAnalysisData,
    initTimeAllocationPageData,
    initAccordionData,
    allocateResources,
    findAnalysisSkill,
    findAllocatedResource,
    initTimeAllocationPageErrors,
    forceAllocationInputs,
    allocateSingleResourceByData,
    trimSubmitData,
    assignApiErrors,
    SINGLE_BOOKING_CALC_MODE,
} from '@/service/Booking';
import { datePickerFormatFromString } from "@/service/DateTime";
import { toast } from "vue3-toastify";
import moment from "moment";
const ALLOCATION_DEBOUNCE_INTERVAL = 650;
const ALLOCATION_DATEPICKER_REFRESH_INTERVAL = 50;

export default {
    name:"AvailableResources",
    components: {
        VueMultiselect,
        VueDatePicker,
        Modal,
        InfoBox,
    },
    props: {
        payload: {
            type: Object,
        }
    },
    data() {
        return {
            accordionData: [],
            accordionActive: true,
            accordionTableActive: true,
            projects: [],
            projectSelected: null,
            phases: [],
            phaseSelected: null,
            startDate: null,
            endDate: null,

            modalSkillOpen: false,
            modalSkillData: [],
            modalBookingOpen: false,
            modalBookingData: [],

            phaseId: null,
            projects: [],
            analysis: {},
            allocation: {},
            allocationErrors: {},
            allocationNumErrors: 0,

            allocationChangeDebounced: null,
            allocationDateRangeCallDebounced: null,
            allocationElement: {
                hours: SINGLE_BOOKING_CALC_MODE.HOURS,
                hours_per_day: SINGLE_BOOKING_CALC_MODE.HOURS_PER_DAY,
                start_date: SINGLE_BOOKING_CALC_MODE.START_DATE,
                end_date: SINGLE_BOOKING_CALC_MODE.END_DATE,
            },
        }
    },
    methods: {
        loadProjectAndPhaseSelect() {
            this.projects.forEach(project => {
                if (project.id == this.analysis.project_id) {
                    this.projectSelected = project;
                    this.phases = project.phases;
                    this.phases.forEach(phase => {
                        if (phase.id == this.analysis.id) {
                            this.phaseSelected = phase;
                        }
                    });
                }
            });
        },
        openSkillsModal(resource) {
            this.modalSkillOpen = true;
            this.modalSkillData = resource;
        },
        openBookingModal(resource) {
            this.modalBookingOpen = true;
            this.modalBookingData = resource;
        },
        modalFormatDateString(dateString) {
            if (!dateString) {
                return '';
            }

            return moment(dateString).format('DD.MM.YYYY');
            // return datePickerFormatFromString(dateString);
        },
        isAllocationHoursInputDisabled(skill) {
            return skill.ongoing;
        },
        allocationStartDateMinDate(skill) {
            return skill.start_date;
        },
        allocationStartDateMaxDate(skill) {
            if (skill.ongoing || !skill.end_date) {
                return null;
            }

            return skill.end_date;
        },
        isAllocationEndDateDisabled(skill) {
            return skill.ongoing;
        },
        allocationEndDateMinDate(skill) {
            return skill.start_date;
        },
        allocationEndDateMaxDate(skill) {
            if (skill.ongoing || !skill.end_date) {
                return null;
            }

            return skill.end_date;
        },
        changeAllocationInput(element, skillId, resourceId) {
            const resourceData = findAllocatedResource(this.allocation, resourceId, skillId);

            if (!resourceData) {
                return;
            }

            if (resourceData.hours_per_day && !resourceData.start_date) {
                resourceData.start_date = this.getDefaultResourceStartDate(skillId);
            }

            setTimeout(() => {
                this.calculateSinleBookingDate(element, skillId, resourceData);
            }, ALLOCATION_DATEPICKER_REFRESH_INTERVAL);
        },
        changeAllocationDate(element, skillId, resourceId) {
            const resourceData = findAllocatedResource(this.allocation, resourceId, skillId);

            if (!resourceData) {
                return;
            }

            setTimeout(() => {
                this.calculateSinleBookingDate(element, skillId, resourceData);
            }, ALLOCATION_DATEPICKER_REFRESH_INTERVAL);
        },
        async calculateSinleBookingDate(element, skillId, resourceData) {
            var mode = null;

            if (resourceData.hours && resourceData.hours_per_day && resourceData.start_date) {
                mode = SINGLE_BOOKING_CALC_MODE.END_DATE;
            }

            if (resourceData.hours && resourceData.hours_per_day && element === SINGLE_BOOKING_CALC_MODE.END_DATE) {
                mode = SINGLE_BOOKING_CALC_MODE.START_DATE;
            }

            if (resourceData.hours_per_day && resourceData.start_date && resourceData.end_date) {
                mode = SINGLE_BOOKING_CALC_MODE.HOURS;
            }

            if (!mode) {
                return;
            }

            clearTimeout(this.allocationDateRangeCallDebounced);
            this.allocationDateRangeCallDebounced = setTimeout(async () => {
                try {
                    const { data } = await getSingleBookingDateRangeCalc(this.phaseId, skillId, resourceData.id, resourceData.hours, resourceData.hours_per_day, resourceData.start_date, resourceData.end_date, mode);
                    allocateSingleResourceByData(this.allocation, data, this.allocationErrors);
                } catch (e) {
                    toast.error(e.response?.data.message);
                    return;
                }
            }, ALLOCATION_DEBOUNCE_INTERVAL);
        },
        getDefaultResourceStartDate(skillId) {
            let defaultStartDate = null;

            if (this.analysis.start_date) {
                defaultStartDate = datePickerFormatFromString(this.analysis.start_date);
            }

            const analysisSkill = findAnalysisSkill(this.analysis, skillId);

            if (analysisSkill && analysisSkill.start_date) {
                defaultStartDate = datePickerFormatFromString(analysisSkill.start_date);
            }

            return defaultStartDate;
        },
        async submitBooking() {
            try {
                const { data } = await bookProjectPhase(this.phaseId, trimSubmitData(this.allocation));
                if (data.success) {
                    this.$router.push({ name: 'booking-phase-timeline', params: { id: this.phaseId } });
                }
            } catch (e) {
                assignApiErrors(this.allocationErrors, e.response.data);
                toast.error(e.response?.data.message);
                return;
            }
        }
    },
    watch: {
        allocation: {
            immediate: true,
            deep: true,
            handler(newAllocation) {
                forceAllocationInputs(newAllocation);
                clearTimeout(this.allocationChangeDebounced);
                this.allocationChangeDebounced = setTimeout(() => {
                    this.allocationNumErrors = allocateResources(this.analysis, newAllocation, this.allocationErrors);
                }, ALLOCATION_DEBOUNCE_INTERVAL);
            },
        },
    },
    async created() {
        this.phaseId = this.payload.id;
        let analysisData = null;

        try {
            const { data } = await getAllProjectsApi();
            this.projects = data;
        } catch (e) {
            toast.error(e.response?.data.message);
            return;
        }

        try {
            const { data } = await getProjectPhaseAnalysisApi(this.phaseId);
            analysisData = data;
        } catch (e) {
            toast.error(e.response?.data.message);
            return;
        }

        try {
            validateAnalysisData(analysisData);
        } catch (e) {
            toast.error(e);
            return;
        }

        this.analysis = analysisData;
        this.allocation = initTimeAllocationPageData(analysisData);
        this.allocationErrors = initTimeAllocationPageErrors(this.allocation);
        this.accordionData = initAccordionData(analysisData);

        this.loadProjectAndPhaseSelect();
    },
}
</script>
<style lang="scss" scoped>
    .card__body {
        padding: 24px 0;
        margin-top: 10px;
        border-top: 1px solid #E4E7EC;
    }

    .accordion-trigger {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 8px;
        box-shadow: 0px 1px 2px 0px rgba(21, 28, 36, 0.05);
        background-color: #141C25;
        svg {
            transform: rotate(180deg);
            transition: all .3s ease;
        }

        &:hover {
            background-color: darken(#141C25, 10%);
        }

        &.active {
            svg {
                transform: rotate(0deg);
            }
        }
    }

    .accordion-table-trigger {

        svg {
            transition: all .3s ease;
            transform: rotate(90deg);
        }

        &.active {
            
            svg {
                transform: rotate(0deg);
            }
        }
    }

    .date-table {
        max-width: 250px;
    }

    .resources-accordion {
        border-bottom: 1px solid #E4E7EC;
        cursor: pointer;

        p {
            font-weight: 500;
            min-width: 100px;
        }
    }

    .info-box-wrapper {
        gap: 20px;
    }

    .accordion-header {
        gap: 30px;
        padding: 20px;
    }

    .accordion-body {
        margin-left: 40px;
        width: calc(100% - 40px);
        background-color: #F9FAFB;
        border-top: 1px solid #E4E7EC;
        padding: 0 20px 14px;

        .table {
            width: auto;
        }

        td {
            font-weight: 500;
            background-color: #fff;
        }

        th {
            white-space: nowrap;
        }

        tbody {
            tr:last-child {
                td {
                    border-bottom: none;
                }
            }
        }

        .input, .date-table {
            min-width: 140px;
        }
    }

    .view-details {
        padding: 0 6px;
        border-radius: 6px;
        height: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #344051;
        gap: 6px;
        font-size: 14px;
        font-weight: 500;
        border: 1px solid #E4E7EC;
        background-color: #fff;
    }

    .error-field {
        color: #ff0000;
    }

    .error-date-field {
        border: 1px solid #ff0000;
    }

    .skill-info {
        color: #6a7585;
    }

    .submit-container {
        float: right;
        margin-top: 16px;
        a {
            margin-right: 10px;
        }
    }
</style>