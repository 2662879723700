import { createRouter, createWebHistory } from 'vue-router';
import AuthLayout from '../../src/layout/AuthLayout.vue';
import DashboardLayout from '../../src/layout/DashboardLayout.vue';
import LoginPage from '../view/auth/LoginPage.vue';
import EmployeePage from '../view/EmployeePage.vue';
import EmployeeDashboard from '../view/employee/EmployeeDashboard.vue';
import EmployeeAdd from '../view/employee/EmployeeAdd.vue';
import ProjectPage from '../view/ProjectPage.vue';
import ProjectDashboard from '../view/project/ProjectDashboard.vue';
import ProjectAdd from '../view/project/ProjectAdd.vue';
import EstimationsPage from '../view/EstimationsPage.vue';
import EstimationsDashboard from '../view/estimations/EstimationsDashboard.vue';
import EstimationAdd from '../view/estimations/EstimationAdd.vue';
import PhaseAdd from '../view/project/PhaseAdd.vue';
import ContractType from '../view/ContractTypesPage.vue';
import StatusOptions from '../view/StatusOptionsPage.vue';
import ClientsPage from '../view/Clients.vue';
import Positions from '../view/PositionsPage.vue';
import Skills from '../view/SkillsPage.vue';
import SeniorityLevels from '../view/SeniorityLevelsPage.vue';
import Teams from '../view/TeamsPage.vue';
import Location from '../view/LocationPage.vue';
import EmployeeViewEdit from '@/view/employee/EmployeeViewEdit.vue';
import ProjectViewEdit from '@/view/project/ProjectViewEdit.vue';
import BookingTimeline from '@/view/booking/BookingTimeline.vue'
import PhasesViewEdit from '@/view/project/PhasesViewEdit.vue';
import BookingProjectPage from '@/view/booking/BookingProject.vue'
// import BookingPhase from '@/view/booking/BookingPhase.vue';
import BookingPhaseTimeline from '@/view/booking/BookingPhaseTimeline.vue';
import DashboardPage from '@/view/DashboardPage.vue';
import TimeUtilization from '@/view/dashboard/TimeUtilization.vue';
import Estimations from '@/view/dashboard/Estimations.vue';
import TotalCost from '@/view/dashboard/TotalCost.vue';
import Bookings from '@/view/booking/Bookings.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  routes: [
    {
      path: '/',
      name: 'login',
      component: LoginPage,
      meta: {
        layout: AuthLayout,
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: DashboardPage,
      meta: {
        layout: DashboardLayout,
      },
    },

    {
      path: '/dashboard',
      component: DashboardPage,
      meta: {
        layout: DashboardLayout,
        breadcrumb: 'Project',
      },
      children: [
        {
          path: '',
          name: 'dashboard',
          component: TimeUtilization,
          meta: {
            layout: DashboardLayout,
          },
        },
        {
          path: 'estimations-dashboard',
          name: 'estimations-dashboard',
          component: Estimations,
          meta: {
            layout: DashboardLayout,
          },
        },
        {
          path: 'total-cost',
          name: 'total-cost',
          component: TotalCost,
          meta: {
            layout: DashboardLayout,
          },
        },
      ]
    },
    {
      path: '/clients',
      name: 'clients',
      component: ClientsPage,
      meta: {
        layout: DashboardLayout,
      },
    },
    // {
    //   path: '/project',
    //   name: 'project',
    //   component: ProjectPage,
    //   meta: {
    //     layout: DashboardLayout,
    //   },
    // },
    {
      path: '/estimations',
      name: 'estimations',
      component: EstimationsPage,
      meta: {
        layout: DashboardLayout,
      },
    },
    {
      path: '/contract-types',
      component: ContractType,
      name: 'contract-types',
      meta: {
        layout: DashboardLayout,
      },
    },
    {
      path: '/status-options',
      component: StatusOptions,
      name: 'status-options',
      meta: {
        layout: DashboardLayout,
      },
    },
    {
      path: '/positions',
      component: Positions,
      name: 'positions',
      meta: {
        layout: DashboardLayout,
      },
    },
    {
      path: '/skills',
      component: Skills,
      name: 'skills',
      meta: {
        layout: DashboardLayout,
      },
    },
    {
      path: '/seniority-levels',
      component: SeniorityLevels,
      name: 'seniority-levels',
      meta: {
        layout: DashboardLayout,
      },
    },
    {
      path: '/teams',
      component: Teams,
      name: 'teams',
      meta: {
        layout: DashboardLayout,
      },
    },
    {
      path: '/location',
      component: Location,
      name: 'location',
      meta: {
        layout: DashboardLayout,
      },
    },
    {
      path: '/booking-timeline',
      component: BookingTimeline,
      name: 'booking-timeline',
      meta: {
        layout: DashboardLayout,
      },
    },
    {
      path: '/employee',
      component: EmployeePage,
      meta: {
        layout: DashboardLayout,
        breadcrumb: 'Employee',
      },
      children: [
        {
          path: '',
          name: 'employee',
          component: EmployeeDashboard,
          meta: {
            layout: DashboardLayout,
            // breadcrumb: 'Employee',

          },
        },
        {
          path: 'add-employee',
          component: EmployeeAdd,
          name: 'addEmployee',
          meta: {
            layout: DashboardLayout,
            breadcrumb: 'Add Employee ',
          },
        },
        {
          path: 'view-edit/:id',
          component: EmployeeViewEdit,
          name: 'employee-view-edit',
          meta: {
            layout: DashboardLayout,
            breadcrumb: 'View & Edit ',
          },
        },
      ]
    },
    {
      path: '/project',
      component: ProjectPage,
      meta: {
        layout: DashboardLayout,
        breadcrumb: 'Project',
      },
      children: [
        {
          path: '',
          name: 'project',
          component: ProjectDashboard,
          meta: {
            layout: DashboardLayout,
            // breadcrumb: 'Employee',

          },
        },
        {
          path: 'add-project',
          component: ProjectAdd,
          name: 'add-project',
          meta: {
            layout: DashboardLayout,
            breadcrumb: 'Add Project ',
          },
        },
        {
          path: 'view-edit/:id',
          component: ProjectViewEdit,
          name: 'project-view-edit',
          meta: {
            layout: DashboardLayout,
            breadcrumb: 'View & Edit ',
          },
        },
        {
          path: 'add-phase/:id',
          component: PhaseAdd,
          name: 'add-phase',
          meta: {
            layout: DashboardLayout,
            breadcrumb: 'Add Phases ',
          },
        },
        {
          path: 'edit-phase/:id',
          component: PhasesViewEdit,
          name: 'view-edit-phase',
          meta: {
            layout: DashboardLayout,
            breadcrumb: 'View & Edit Phases ',
          },
        },
      ]
    },
    {
      path: '/estimations',
      component: EstimationsPage,
      meta: {
        layout: DashboardLayout,
        breadcrumb: 'Estimations',
      },
      children: [
        {
          path: '',
          name: 'estimations',
          component: EstimationsDashboard,
          meta: {
            layout: DashboardLayout,
          },
        },
        {
          path: 'add-estimation',
          component: EstimationAdd,
          name: 'add-estimation',
          meta: {
            layout: DashboardLayout,
            breadcrumb: 'New Estimation',
          },
        },
      ]
    },
    {
      path: '/booking-project/:id?',
      component: BookingProjectPage,
      name: 'booking-project',
      meta: {
        layout: DashboardLayout,
        breadcrumb: 'Booking Project',
      },
    },
    {
      path: '/bookings',
      component: Bookings,
      name: 'bookings',
      meta: {
        layout: DashboardLayout,
        breadcrumb: 'Bookings',
      },
    },
    // TO BE REMOVED /booking-phase/:id
    // {
    //   path: '/booking-phase/:id',
    //   component: BookingPhase,
    //   name: 'booking-phase',
    //   meta: {
    //     layout: DashboardLayout,
    //     breadcrumb: 'Booking Phase',
    //   },
    // },
    {
      path: '/booking-phase-timeline/:id',
      component: BookingPhaseTimeline,
      name: 'booking-phase-timeline',
      meta: {
        layout: DashboardLayout,
        breadcrumb: 'Booking Phase Timeline',
      },
    },
  ],
});

export default router;
