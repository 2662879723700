import { dateFromString, dateNextYear } from "@/service/DateTime";

export const createTaskListFromApiData = (data, startDate, endDate) => {
    if (typeof startDate === 'string') startDate = dateFromString(startDate);
    if (typeof endDate === 'string') endDate = dateFromString(endDate);

    if (!endDate) {
        endDate = dateNextYear();
    }

    const tasks = [];

    data.forEach(rawData => {
        let parentTask = findParentTask(tasks, generateParentTaskId(rawData.resource.id));

        if (!parentTask) {
            parentTask = createParentTaskFromApiData(rawData, startDate, endDate);
            tasks.push(parentTask);
        }

        tasks.push(createTaskFromApiData(rawData, parentTask, startDate, endDate));
    });

    tasks.forEach(task => {
        if (task.taskType === 'parent') {
            let parentStartDate = null;
            let parentEndDate = null;
            let labelChunks = [];

            task.tasks.forEach(childTask => {
                if (!parentStartDate) {
                    parentStartDate = childTask.start;
                }

                if (childTask.start < parentStartDate) {
                    parentStartDate = childTask.start;
                }

                if (!parentEndDate) {
                    parentEndDate = childTask.end;
                }

                if (childTask.end > parentEndDate) {
                    parentEndDate = childTask.end;
                }

                labelChunks = uniqueArray(labelChunks);
                labelChunks.push(childTask.label);
            });

            if (parentStartDate) {
                task.start = parentStartDate;
            }

            if (parentEndDate) {
                task.end = parentEndDate;
            }

            task.label = labelChunks.join(' / ');
        }
    });

    tasks.push(createPlaceHolderTaskFromApiData(startDate, endDate));

    return tasks;
};

export const createParentTaskFromApiData = (taskRawData, startDate, endDate) => {
    const parentTask = {
        id: generateParentTaskId(taskRawData.resource.id),
        label: '',
        description: taskRawData.resource.first_name + ' ' + taskRawData.resource.last_name,
        start: startDate,
        end: endDate,
        progress: 0,
        type: 'task',
        style: {
            base: {
                fill: '#fafafa',
                stroke: '#AAAAAA',
            }
        },
        taskType: 'parent',
        tasks: [],
    };

    return parentTask;
};

export const createTaskFromApiData = (taskRawData, parentTask, startDate, endDate) => {
    let taskStartDate = dateFromString(taskRawData.start_date);

    if (taskStartDate < startDate) {
        taskStartDate = startDate;
    }

    let taskEndDate = endDate;

    if (taskRawData.end_date) {
        const givenTaskEndDate = dateFromString(taskRawData.end_date);

        if (givenTaskEndDate < endDate) {
            taskEndDate = givenTaskEndDate;
        }
    }

    const task = {
        id: generateTaskId(taskRawData.id),
        label: taskRawData.skill.name + ' - ' + taskRawData.hours_per_day + 'h',
        description: taskRawData.project.name + ' - ' + taskRawData.phase.name,
        start: taskStartDate,
        end: taskEndDate,
        progress: 0,
        type: 'task',
        parentId: parentTask.id,
        style: {
            base: {
                fill: '#000000',
                stroke: '#CCCCCC',
            },
        },
    };

    if (taskRawData.project && taskRawData.project.color) {
        task.style.base.fill = taskRawData.project.color;
    }

    parentTask.tasks.push(task);

    return task;
};

export const createTaskListByPhaseFromApiData = (data, startDate, endDate) => {
    if (typeof startDate === 'string') startDate = dateFromString(startDate);
    if (typeof endDate === 'string') endDate = dateFromString(endDate);

    if (!endDate) {
        endDate = dateNextYear();
    }

    const tasks = [];

    data.forEach(rawData => {
        let parentTask = findParentTask(tasks, generateParentTaskId(rawData.project.id + '_' + rawData.phase.id));

        if (!parentTask) {
            parentTask = createPhaseParentTaskFromApiData(rawData, startDate, endDate);
            tasks.push(parentTask);
        }

        tasks.push(createPhaseTaskFromApiData(rawData, parentTask, startDate, endDate));
    });

    tasks.forEach(task => {
        if (task.taskType === 'parent') {
            let parentStartDate = null;
            let parentEndDate = null;
            let labelChunks = [];

            task.tasks.forEach(childTask => {
                if (!parentStartDate) {
                    parentStartDate = childTask.start;
                }

                if (childTask.start < parentStartDate) {
                    parentStartDate = childTask.start;
                }

                if (!parentEndDate) {
                    parentEndDate = childTask.end;
                }

                if (childTask.end > parentEndDate) {
                    parentEndDate = childTask.end;
                }

                labelChunks.push(childTask.name);
            });

            if (parentStartDate) {
                task.start = parentStartDate;
            }

            if (parentEndDate) {
                task.end = parentEndDate;
            }

            labelChunks = uniqueArray(labelChunks);
            task.label = labelChunks.join(' / ');
        }
    });

    tasks.push(createPlaceHolderTaskFromApiData(startDate, endDate));

    return tasks;
};

export const createPhaseParentTaskFromApiData = (rawData, startDate, endDate) => {
    return {
        id: generateParentTaskId(rawData.project.id + '_' + rawData.phase.id),
        label: '',
        description: rawData.project.name + ' ' + rawData.phase.name,
        start: startDate,
        end: endDate,
        progress: 0,
        type: 'task',
        style: {
            base: {
                fill: '#fafafa',
                stroke: '#AAAAAA',
            }
        },
        taskType: 'parent',
        tasks: [],
    };
};

export const createPhaseTaskFromApiData = (taskRawData, parentTask, startDate, endDate) => {
    const taskStartDate = dateFromString(taskRawData.start_date);

    const task = {
        id: generateTaskId(taskRawData.project.id + '_' + taskRawData.phase.id + '_' + taskRawData.resource.id + '_' + generateRandomId()),
        label: taskRawData.skill.name + ' - ' + taskRawData.hours_per_day + 'h',
        description: taskRawData.resource.first_name + ' ' + taskRawData.resource.last_name,
        start: (taskStartDate > startDate) ? taskStartDate : startDate,
        end: taskRawData.end_date ? dateFromString(taskRawData.end_date) : endDate,
        progress: 0,
        type: 'task',
        parentId: parentTask.id,
        style: {
            base: {
                fill: '#000000',
                stroke: '#CCCCCC',
            },
        },
        name: taskRawData.resource.first_name + ' ' + taskRawData.resource.last_name,
    };

    if (typeof taskRawData.project && taskRawData.project.color) {
        task.style.base.fill = taskRawData.project.color;
    }

    parentTask.tasks.push(task);

    return task;
};

export const createPlaceHolderTaskFromApiData = (startDate, endDate) => {
    return {
        id: 'placeholder',
        description: '',
        start: startDate,
        end: endDate,
        progress: 0,
        type: 'task',
        style: {
            base: {
                display: 'none',
            }
        }
    };
};

const generateParentTaskId = (rawId) => 'parent_' + rawId;

const generateTaskId = (rawId) => 'task_' + rawId;

const findParentTask = (tasks, parentTaskId) => {
    return tasks.find((task) => task.id === parentTaskId);
}

const generateRandomId = (length) => {
    if (!length) {
        length = 10;
    }
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;

    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    return result;
}

const uniqueArray = (array) => {
    return array.filter((value, index, self) => self.indexOf(value) === index);
}
