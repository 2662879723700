<template>
  <main :class="{'h-100': loadPage}">
    <teleport to="#modals">
      <transition name="fade">
        <Modal
          v-if="openModal"
          class="modal"
          :closeButton="false"
          @close="openModal = false"
        >
          <template #header>
            <div class="flag">
              <div class="flag__icon">
                <SvgIcon :icon="svgIcons.modalPen"
                               icon-width="48px"
                               icon-height="48px"/>
              </div>
              <div class="flag__content">
                <h2 class="u-font-500 mb0">Add new Skills</h2>
              </div>
            </div>
          </template>
          <template #body>
            <Form :validation-schema="schema">
              <div class="input-box">
                <p>Select Parent</p>
                <VueMultiselect v-model="skillSelected" placeholder="Select one" :options="allSkills" label="name" track-by="id">
                  <template #noResult>
                      <span>No results</span>
                  </template>
                </VueMultiselect>
              </div>
              <div class="input-box">
                  <p>Skills name</p>
                  <div class="relative">
                      <Field type="text" name="addSkillName" class="input input--primary" maxlength="20" :placeholder="'Enter'" v-model="skillValue"/>
                      <ErrorMessage class="text-error" name="addSkillName"  />
                  </div>
              </div>
            </Form>
          </template>
          <template #footer>
            <div class="o-flex modal-button-wrapper">
              <a
                href="#"
                class="button button--secondary"
                @click="openModal = false"
                >Cancel</a
              >
              <button type="button" :disabled="workingAdd || skillValue.length <= 1" @click="addSkill" class="button button--primary">Save</button>
            </div>
          </template>
        </Modal>
      </transition>
    </teleport>
    <teleport to="#modals">
        <transition name="fade">
          <Modal
            v-if="openModalEdit"
            class="modal"
            :closeButton="false"
            @close="openModalEdit = false"
          >
            <template #header>
              <div class="flag">
                <div class="flag__icon">
                  <SvgIcon :icon="svgIcons.modalPen"
                               icon-width="48px"
                               icon-height="48px"/>
                </div>
                <div class="flag__content">
                  <h2 class="u-font-500 mb0">Edit Skill</h2>
                </div>
              </div>
            </template>
            <template #body>
              <Form :validation-schema="schema" class="o-flex o-flex--end o-flex--wrap o-flex--justify gap-10">
                <div class="input-box mb0">
                    <p>Skill name</p>
                    <div class="relative">
                        <Field type="text" name="addSkillName" class="input input--primary" maxlength="20" :placeholder="'Enter'" v-model="skillValue"/>
                        <ErrorMessage class="text-error" name="addSkillName"  />
                    </div>
                </div>
                <ToggleSwitch :id="'2'" :status="switchStatus" @status="handleStatusChange" class="toggle-switch"/>
              </Form>
            </template>
            <template #footer>
              <div class="o-flex modal-button-wrapper">
                <a
                  href="#"
                  class="button button--secondary"
                  @click="openModalEdit = false"
                  >Cancel</a
                >
                <button type="button" :disabled="workingEdit || skillValue.length <= 1" @click="editTreeItem" class="button button--primary">Save</button>
              </div>
            </template>
          </Modal>
        </transition>
    </teleport>
    <teleport to="#modals">
      <transition name="fade">
        <Modal
          v-if="openModalDelete"
          class="modal"
          :closeButton="false"
          @close="openModalDelete = false"
        >
          <template #header>
            <div class="flag">
              <div class="flag__icon">
                <SvgIcon :icon="svgIcons.modalPen"
                               icon-width="48px"
                               icon-height="48px"/>
              </div>
              <div class="flag__content">
                <h2 class="u-font-500 mb0">Delete Skill</h2>
              </div>
            </div>
          </template>
          <template #body>
            <p class="mb0">Are you sure you want to delete <strong>- {{skillValue}} -</strong></p>
          </template>
          <template #footer>
            <div class="o-flex modal-button-wrapper">
              <a
                href="#"
                class="button button--secondary"
                @click="openModalDelete = false"
                >Cancel</a
              >
              <button type="button" :disabled="workingDelete" @click="deleteSkill" class="button button--primary">Delete</button>
            </div>
          </template>
        </Modal>
      </transition>
    </teleport>
    <div class="card mb">
      <div class="card__header o-flex o-flex--center o-flex--justify o-flex--wrap">
        <div class="flag">
          <div class="flag__icon">
            <SvgIcon  :icon="svgIcons.skillsIcon"
                               icon-width="38px"
                               icon-height="38px"/>
          </div>
          <div class="flag__content">
            <h2 class="u-font-500 mb0">Skills</h2>
          </div>
        </div>
        <a
          href="javascript:;"
          @click="addNewSkill"
          class="button button--primary"
          >Add new</a
        >
      </div>
      <div class="card__body">
      </div>
    </div>
    <div class="card o-flex--auto" v-if="loadPage" :class="{'h-100': loadPage}">
      <div class="card__body h-100">
        <Loader/>
      </div>
    </div>
    <Tree v-else id="tree-skills" class="tree-skills" ref="tree-skills" :custom-options="treeOptions" :custom-styles="treeStyles" :nodes="treeData">
    </Tree>
  </main>
</template>
<script>
import ToggleSwitch from '../components/ToggleSwitch/ToggleSwitch.vue';
import Modal from '../components/Modal/Modal.vue';
import Tree from 'vuejs-tree';
import VueMultiselect from 'vue-multiselect'
import { fetchSkillTree, addSkillTree, updateSkillTree, deleteSkillTree, getAllSkills } from '@/api/skill';
import { toast } from 'vue3-toastify';
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import Loader from '../components/Loader/Loader.vue';
import SvgIcon from '@/components/SvgIcons/SvgIcons.vue'
import svgIcons from '@/assets/icons/icons';

export default {
  name: 'Skills',
  components: {
    Modal,
    Tree,
    VueMultiselect,
    Field,
    Form,
    ErrorMessage,
    ToggleSwitch,
    Loader,
    SvgIcon,
  },

  data() {
    return {
      svgIcons,
      loadPage: true,
      workingAdd: false,
      workingEdit: false,
      workingDelete: false,
      openModal: false,
      openModalEdit: false,
      openModalDelete: false,
      treeData: [],
      allSkills:[],
      skillSelected: null,
      skillItemId: null,
      skillValue:"",
      editId: null,
      editParentId: null,
      switchStatus: false,
      schema: yup.object({
        addSkillName: yup
          .string()
          .required('Skill value is required')
          .min(2, 'Must be at least 2 characters')
      }),
    };
  },

  computed: {
    treeStyles() {
      return {
        tree: {
          style: {
            height: 'auto',
            maxHeight: '100%',
          },
        },
        text: {
          class: '',
        },
        editNode: {
          class: '',
        },
        deleteNode: {
          class: '',
        },
      }
    },

    treeOptions() {
      return {
        treeEvents: {
          expanded: {
            state: true,
            fn: null,
          },
          collapsed: {
            state: false,
            fn: null,
          },
          selected: {
            state: false,
            fn: null,
          },
          checked: {
            state: true,
            fn: null,
          }
        },
        events: {
          expanded: {
            state: true,
            fn: null,
          },
          selected: {
            state: false,
            fn: null,
          },
          checked: {
            state: true,
            fn: null,
          },
          editableName: {
            state: false,
            fn: null,
            calledEvent: null,
          }
        },
        addNode: { state: false, fn: null, appearOnHover: false },
        editNode: { state: true, fn: this.treeEditNode, appearOnHover: true },
        deleteNode: { state: true, fn: this.treeDeleteNode, appearOnHover: true },
        showTags: true,
      };
    }
  },

  methods: {
    handleStatusChange(newStatus) {
      this.switchStatus = newStatus;
    },

    addNewSkill() {
      this.openModal = true;
      this.getAllSkills();
      this.skillValue = '';
    },
    async addSkill() {
      this.workingAdd = true;

      try {
        await addSkillTree(this.skillValue , this.skillSelected?.id);
        toast.success(this.skillValue + " is added.", {"position": "top-center", autoClose: 2000});
        this.skillValue = '';
        // this.skillSelected = "Choose skill",
        this.openModal = false;
        this.treeData = [];
        this.allSkills = [],
        this.workingAdd = false;
        this.getAllSkills();
        this.loadSkillTree();
      } catch (e) {
        toast.error(e.response?.data?.message, {"position": "top-center", autoClose: 2000});
        setTimeout(() => {
          this.workingAdd = false;
        }, "3000");
      }

      this.skillSelected = null;
    },

    async getAllSkills() {
      try {
        const allSkills = await getAllSkills();
        this.allSkills = allSkills.data

      } catch (e) {
        if (e.response && e.response?.status !== 401) {
          toast.error(e.response?.data.message ?? e.message, { "position": "top-center", autoClose: 2000 });
        }
      }
    },

    treeEditNode(node) {
      this.skillValue = node.text;
      this.editId = node.id;
      this.editParentId = node.parent_id;
      this.switchStatus = node.status;
      this.getAllSkills()
      this.openModalEdit = true;
    },

    async editTreeItem() {
      this.workingEdit = true;
        try {
          await updateSkillTree(this.editId, this.skillValue, this.editParentId, this.switchStatus)
          toast.success(this.skillValue + " is edited successfuly.", {"position": "top-center", autoClose: 2000});
          this.treeData = [];
          this.skillValue="";
          this.openModalEdit = false;
          this.workingEdit = false;
          this.loadSkillTree();
          this.getAllSkills();

        } catch (e) {
          toast.error(e.response?.data?.message, {"position": "top-center", autoClose: 2000});
          setTimeout(() => {
            this.workingEdit = false;
          }, "3000");
        }
    },

    async treeDeleteNode(node) {
      this.skillItemId = node.id;
      this.skillValue = node.text;
      this.openModalDelete = true;
    },

    async deleteSkill() {
      this.workingDelete = true;
      try {
        await deleteSkillTree(this.skillItemId);
        toast.success(this.skillValue + " is deleted successfuly.", {"position": "top-center", autoClose: 2000});
        this.treeData = [];
        this.openModalDelete = false;
        this.workingDelete = false;
        this.loadSkillTree();
      } catch (e) {
        if(e.response.status === 409) {
          toast.error("You can not delete this skill", {"position": "top-center", autoClose: 2000});
          setTimeout(() => {
            this.workingDelete = false;
          }, "3000");
          this.openModalDelete = false;
        }else {
          toast.error(e.response?.data?.message, {"position": "top-center", autoClose: 2000});
          this.openModalDelete = false;
          setTimeout(() => {
            this.workingDelete = false;
          }, "3000");
        }
      }
    },
    async loadSkillTree() {
      const treeCallResponse = await fetchSkillTree().catch((error) => {
        if (error.response && error.response?.status !== 401) {
          toast.error(error?.message);
        }
        return
      });

      if (treeCallResponse.data && Array.isArray(treeCallResponse.data)) {
        treeCallResponse.data.map((rawSkill) => {
          this.addSkillToData(rawSkill);

        });
      }
    },

    addSkillToData(rawSkill, parentTreeSkill) {
      const treeSkill = this.assignTreeSkillDataFromRawSkill(rawSkill);

      if (rawSkill.all_children && Array.isArray(rawSkill.all_children)) {
        rawSkill.all_children.map((rawChildSkill) => {
          this.addSkillToData(rawChildSkill, treeSkill);
        });
      }

      if (parentTreeSkill && rawSkill.parent_id === parentTreeSkill.id) {
        parentTreeSkill.nodes.push(treeSkill);
      } else {
        this.loadPage = false;
        this.treeData.push(treeSkill);
      }
    },

    assignTreeSkillDataFromRawSkill(rawSkill) {
      const treeSkill = {
        id: rawSkill.id,
        parent_id: rawSkill.parent_id,
        text: rawSkill.name,
        status: rawSkill.status,
        definition: rawSkill.name,
        checkable: true,
        selectable: false,
        expandable: true,
        state: {
          checked: rawSkill.status === "active",
          expanded: true,
          selected: false,
        },
        nodes: [],
      };

      return treeSkill;
    },

  },

  created() {
    this.loadSkillTree();
    this.getAllSkills();
  },
};
</script>

<style lang="scss" scoped>
  .modal-button-wrapper {
    gap: 16px;

    .button {
      height: 44px;
    }
  }

  .toggle-switch {
    margin-bottom: 8px;
  }

  .flag__content {
    color: var(--light-dark);
  }
</style>

<style lang="scss">
  .tree-skills {
    >ul {
      max-height: auto!important;
      display: grid !important;
      width: 100%;
      gap: 30px;
      grid-template-columns: repeat(4, 1fr);

      @media(max-width: 1400px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
      }

      @media(max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media(max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .icon_parent {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(0, -50%);
      width: 24px!important;
      height: 24px!important;
      border-radius: 8px;
      display: flex!important;
      align-items: center;
      justify-content: center;
      background-color: #fff!important;
      border: 1px solid #E4E7EC;
      box-shadow: 0px 1px 2px 0px rgba(21, 28, 36, 0.05);
      transition: all .2s ease-in;
        margin-right: 30px!important;

      &:hover {
        background-color: #E4E7EC!important;
      }

      &:before {
        width: 16px;
        height: 16px;
        display: inline-block;
        content: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22none%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20stroke%3D%22%23141C25%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%221.5%22%20d%3D%22m9.576%203.768.986-.987c.52-.52%201.365-.52%201.886%200l.943.943c.52.52.52%201.365%200%201.885l-.987.987M9.576%203.768l-6.41%206.41a1.333%201.333%200%200%200-.386.826l-.162%201.827c-.036.413.31.759.723.722l1.827-.161c.312-.028.604-.164.826-.385l6.41-6.41m-2.828-2.83%202.828%202.83%22%2F%3E%3C%2Fsvg%3E');
      }

      &:last-child {
        margin-right: 0!important;

        &:before {
          content: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22none%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20stroke%3D%22%23141C25%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%221.5%22%20d%3D%22m13.333%206-1.33%207.564a1.333%201.333%200%200%201-1.313%201.103H5.31a1.333%201.333%200%200%201-1.313-1.103L2.667%206M14%204h-3.75m0%200V2.667c0-.737-.597-1.334-1.333-1.334H7.083c-.736%200-1.333.597-1.333%201.334V4m4.5%200h-4.5M2%204h3.75%22%2F%3E%3C%2Fsvg%3E');
        }
      }
    }

    ul {
      margin-bottom: 0;
      overflow: initial!important;

      li {
        width: 100%!important;
      }

      .row_data {
        display: flex;
        align-items: center;
        gap: 10px;
        height: 44px!important;
        border-radius: 10px;
        // padding: 0 10px;
        padding: 0 70px 0 10px;
        background-color: #fff;
        flex: 1;
        margin-bottom: 8px;
        position: relative;

        &:not(:has(input:checked)) + ul {
          .row_data {

            .icon_parent:not(:last-child) {
              opacity: .2;
              pointer-events: none;
            }
          }
        }

        input {
          position: absolute;
          z-index: -1;
          visibility: hidden;
          opacity: 0;
        }

        input:not(:checked) + span ,  input:not(:checked) ~ span.icon_parent:last-child{
          opacity: .2;
          pointer-events: none;
        }

        .folder_icon_active, .folder_icon {
          display: none;
        }

        span:first-child {
          display: flex;
          align-items: center;

          i {
            position: relative;
            border: none;
            width: 16px;
            height: 16px;

            &.expanded {
              transform: rotate(180deg);
            }

            &:before {
              width: 16px;
              height: 16px;
              display: inline-block;
              content: url('data:image/svg+xml;utf8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M4%206L8%2010L12%206%22%20stroke%3D%22%23141C25%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E');
            }
          }
        }
      }

      ul {
        position: relative;

        padding-left: 32px!important;

        &:before {
          position: absolute;
          right: 100%;
          content:"";
          top: -8px;
          height: calc(100% - 15px);
          transform: translate(18px, 0);
          width: 1px;
          background-color: #CED2DA;

        }

        li {
          position: relative;

          &:before {
            position: absolute;
            right: 100%;
            content:"";
            top: 20px;
            height: 1px;
            transform: translate(-1px, 0);
            width: 14px;
            background-color: #CED2DA;
          }
        }
      }
    }
  }



</style>

