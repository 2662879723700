<template>
    <main :class="{'h-100': loadPage}">
        <header class="mb">
            <router-link
              active-class="active"
              class="nav__link"
              :to="{name: 'dashboard'}"
            >
                Team Utilization & Cost
            </router-link>
            <router-link
              active-class="active"
              class="nav__link"
              :to="{name: 'estimations-dashboard'}"
            >
                Estimations
            </router-link>
            <router-link
              active-class="active"
              class="nav__link"
              :to="{name: 'total-cost'}"
            >
                Total Cost of Unused Resources
            </router-link>
        </header>

        <div class="card mb0">
            <div class="card__header card--border o-flex o-flex--center o-flex--justify o-flex--wrap pt-">
                <div class="flag">
                    <div class="flag__icon">
                        <img src="@/assets/img/send-dollars.svg" alt="send-dollars" />
                    </div>
                    <div class="flag__content">
                        <h2 class="u-font-500 mb0">Total Cost of Unused Resources</h2>
                    </div>
                </div>
            </div>
            <div class="card__body">
                <VueApexCharts 
                    :height="250"
                    type="bar" 
                    :options="barOptions" 
                    :series="barSeries"
                    />
            </div>
        </div>

    </main>
</template>
<script>
import Loader from '@/components/Loader/Loader.vue';
import VueApexCharts from 'vue3-apexcharts';


export default {
    name: "Estimation",
    components: {Loader, VueApexCharts},
    data() {
        return {
            loadPage: false,
            barSeries: [{
                data: [{
                    x: 'Jan',
                    y: 1200
                }, {
                    x: 'Feb',
                    y: 1400
                }, {
                    x: 'Mar',
                    y: 4000
                }, {
                    x: 'Apr',
                    y: 2300
                }, {
                    x: 'May',
                    y: 2000
                }, {
                    x: 'Jun',
                    y: 4000
                }, {
                    x: 'Jul',
                    y: 2000
                }, {
                    x: 'Avg',
                    y: 1900
                }, {
                    x: 'Sep',
                    y: 1900
                }, {
                    x: 'Oct',
                    y: 4000
                }, {
                    x: 'Nov',
                    y: 1500
                }, {
                    x: 'Dec',
                    y: 2200
                }]
            }],
            barOptions: {
                fill: {
                    colors: ['#3B82F6'],
                    opacity: 1,
                },
                plotOptions: {
                    bar: {
                        distributed: true,
                        columnWidth: '10%'
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                legend: {
                    show: false,
                    position: 'bottom',
                },
            },
        }
    },
}
</script>
<style lang="scss" scoped>
    header {
        display: flex;
        gap: 6px;

        .nav__link {
            padding: 0 16px;
            height: 32px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            color: #637083;

            &.exact-active {
                background-color: #fff;
                color: #141C25;
            }
        }
    }
</style>