<template>
    <main>
        <header class="mb">
            <router-link
              active-class="active"
              class="nav__link"
              :to="{name: 'dashboard'}"
            >
                Team Utilization & Cost
            </router-link>

            <router-link
              active-class="active"
              class="nav__link"
              :to="{name: 'booking-timeline'}"
            >
                Booking Timeline
            </router-link>
        </header>

        <div class="filterTimeline o-flex o-flex--center o-flex--justify o-flex--wrap gap-10">
            <div class="filterTimeline__month o-flex o-flex--center">
                <button @click="timelinePrevous" class="button">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.3333 8.5H4M4 8.5L8 4.5M4 8.5L8 12.5" stroke="#414E62" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <span>{{ timeLabel }}</span>
                <button @click="timelineNext" class="button">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 8.5H12.3333M12.3333 8.5L8.33333 4.5M12.3333 8.5L8.33333 12.5" stroke="#414E62" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>

            <div class="filterTimeline__month o-flex o-flex--center">
                <button @click="timelineZoomIn" class="button">Zoom +</button>
            </div>


            <div class="filterTimeline__month o-flex o-flex--center">
                <button @click="timelineZoomOut" class="button">Zoom -</button>
            </div>

            <div class="filterTimeline__options o-flex o-flex--center gap-10">
                <VueMultiselect
                    v-model="mode"
                    :options="allModes"
                    :close-on-select="true"
                    :multiple="false"
                    :searchable="false"
                    :allow-empty="false"
                    :show-labels="false"
                    class="input--tags"
                />
                <VueMultiselect
                    v-model="interval"
                    :options="allIntervals"
                    :close-on-select="true"
                    :multiple="false"
                    :searchable="false"
                    :allow-empty="false"
                    :show-labels="false"
                    class="input--tags"
                />
                <div class="filter" v-click-outside="dropdownClose">
                    <button @click="dropdown = true" class="button button--secondary" :class="{'active' : activeFilters}">
                        <svg width="20" height="20" class="mr-" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.50004 5.83333V3.5C2.50004 2.94772 2.94775 2.5 3.50004 2.5H16.5001C17.0524 2.5 17.5001 2.94766 17.5001 3.4999L17.5004 5.83333M2.50004 5.83333L7.98416 10.534C8.20581 10.724 8.33337 11.0013 8.33337 11.2933V16.2192C8.33337 16.8698 8.94476 17.3472 9.57591 17.1894L10.9092 16.856C11.3544 16.7447 11.6667 16.3448 11.6667 15.8859V11.2933C11.6667 11.0014 11.7943 10.724 12.0159 10.534L17.5004 5.83333M2.50004 5.83333H17.5004" stroke="#344051" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Filter
                    </button>
                    <transition name="fade">
                        <div class="filter__dropdown" v-if="dropdown">
                            <div class="filter__header o-flex o-flex--end o-flex--justify">
                                <div class="o-flex o-flex--center gap-10">
                                    <img src="../../assets/img/filter-dropdown.svg" alt="">
                                    <h2 class="u-font-500 mb0">Filter</h2>
                                </div>
                                <a v-if="activeFilters" href="javascript:;" @click="clearAll" class="clear">Reset All</a>
                            </div>
                            <div class="filter__body">

                                <div class="o-flex o-flex--wrap gap-10">

                                    <div class="input-box o-flex--1">
                                        <p class="u-font-400">Project</p>
                                        <VueMultiselect name="projects" v-model="projectSelected" class="w-100" placeholder="Select" :options="projects" label="name" track-by="name"/>
                                    </div>

                                    <div class="input-box o-flex--1">
                                        <!-- :disabled="disableFields"  -->
                                        <p class="u-font-400">Phase</p>
                                        <VueMultiselect name="phase" v-model="phaseSelected" class="w-100" placeholder="Select" :options="filteredPhases" label="name" track-by="name">
                                            <template #noResult>
                                                <span>No results</span>
                                            </template>
                                            <template #noOptions>
                                                <span>No phases</span>
                                            </template>
                                        </VueMultiselect>
                                    </div>
                                </div>

                                <div class="u-text-right">
                                    <a href="javascript:;" class="clear" @click="clearProjectPhase">Clear</a>
                                </div>
                                <div class="divider divider-1 mt- mb"></div>

                                <h3>Skills</h3>
                                <div class="o-flex o-flex--center mb">
                                    <div class="check-box mr+">
                                        <input id="skills1" v-model="skill" type="radio" name="skills" :value="1">
                                        <label for="skills1">
                                            <div class="check-icon">
                                            <svg fill="none" width="16" viewBox="0 0 16 16">
                                                <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.333 8.667 6 11.333l6.667-6.666"/>
                                            </svg>
                                            </div>
                                            <p class="check-text">Match any</p>
                                        </label>
                                    </div>
                                    <div class="check-box">
                                        <input id="skills2" v-model="skill" type="radio" name="skills" :value="0">
                                        <label for="skills2">
                                            <div class="check-icon">
                                            <svg fill="none" width="16" viewBox="0 0 16 16">
                                                <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.333 8.667 6 11.333l6.667-6.666"/>
                                            </svg>
                                            </div>
                                            <p class="check-text">Match all</p>
                                        </label>
                                    </div>
                                </div>
                                <div class="relative">
                                    <VueMultiselect
                                    v-model="skillSelected"
                                    placeholder="Type..."
                                    :options="allSkills"
                                    :taggable="true"
                                    :multiple="true"
                                    label="name"
                                    :close-on-select="true"
                                    class="input--tags mb- w-100"
                                    track-by="name"/>
                                    <div class="u-text-right">
                                        <a href="javascript:;" class="clear" @click="skillSelected = []">Clear</a>
                                    </div>
                                </div>
                                <div class="divider divider-1 mt- mb"></div>

                                <div class="o-flex o-flex--wrap gap-10">
                                    <div class="input-box o-flex--1">
                                        <p>Team</p>
                                        <div class="relative">
                                            <VueMultiselect
                                                v-model="teamSelected"
                                                placeholder="Type..."
                                                :options="allTeams"
                                                :taggable="true"
                                                :multiple="true"
                                                label="name"
                                                :close-on-select="true"
                                                class="input--tags mb- w-100"
                                                track-by="name"/>
                                                <div class="u-text-right">
                                                    <a href="javascript:;" class="clear" @click="teamSelected = []">Clear</a>
                                                </div>
                                        </div>
                                    </div>
                                    <div class="input-box o-flex--1">
                                        <p>Person</p>
                                        <div class="relative">
                                            <VueMultiselect
                                                v-model="employees"
                                                placeholder="Type..."
                                                :options="allEmployees"
                                                :taggable="true"
                                                :multiple="true"
                                                track-by="id"
                                                :custom-label="allEmployeesLabel"
                                                :show-labels="false"
                                                :close-on-select="true"
                                                class="input--tags mb- w-100"/>
                                                <div class="u-text-right">
                                                    <a href="javascript:;" class="clear" @click="employees = []">Clear</a>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="divider divider-1 mt- mb"></div>

                                <div class="o-flex gap-10 mt">
                                    <div class="input-box">
                                        <p class="u-font-400">Start Date</p>
                                        <vue-date-picker
                                            auto-apply
                                            :enable-time-picker="false"
                                            :model-type="'yyyy-MM-dd'"
                                            :format="'dd.MM.yyyy'"
                                            @open="handleDate"
                                            placeholder="Enter"
                                            v-model="startDateFilter"
                                            :ui="{ input: 'date-picker' }"
                                        >
                                            <template #input-icon>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3 10V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V10M3 10H21M3 10V6C3 4.89543 3.89543 4 5 4H7M21 10V6C21 4.89543 20.1046 4 19 4H18.5M15 4V2M15 4V6M15 4H10.5M7 6V2" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </template>
                                        </vue-date-picker>
                                    </div>
                                    <div class="input-box">
                                        <p class="u-font-400">End Date</p>
                                        <vue-date-picker
                                            auto-apply
                                            :enable-time-picker="false"
                                            :model-type="'yyyy-MM-dd'"
                                            :format="'dd.MM.yyyy'"
                                            placeholder="Enter"
                                            v-model="endDateFilter"
                                            name="endDate"

                                            :ui="{ input: 'date-picker' }"
                                            :disabled="!startDateFilter"
                                            :min-date="minEndDate"
                                            >
                                            <template #input-icon>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3 10V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V10M3 10H21M3 10V6C3 4.89543 3.89543 4 5 4H7M21 10V6C21 4.89543 20.1046 4 19 4H18.5M15 4V2M15 4V6M15 4H10.5M7 6V2" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </template>
                                        </vue-date-picker>
                                    </div>
                                </div>
                                <div class="u-text-right">
                                    <a href="javascript:;" class="clear" @click="clearDates">Clear</a>
                                </div>

                                <div class="availability" v-if="startDateFilter">
                                    <h3>Availability</h3>
                                    <div class="o-flex o-flex--center mb">
                                        <div class="check-box mr+">
                                            <input id="hours1" v-model="hours" type="radio" name="hours" :value="1">
                                            <label for="hours1">
                                                <div class="check-icon">
                                                <svg fill="none" width="16" viewBox="0 0 16 16">
                                                    <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.333 8.667 6 11.333l6.667-6.666"/>
                                                </svg>
                                                </div>
                                                <p class="check-text">Total hours</p>
                                            </label>
                                        </div>
                                        <div class="check-box">
                                            <input id="hours2" v-model="hours" type="radio" name="hours" :value="0">
                                            <label for="hours2">
                                                <div class="check-icon">
                                                <svg fill="none" width="16" viewBox="0 0 16 16">
                                                    <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.333 8.667 6 11.333l6.667-6.666"/>
                                                </svg>
                                                </div>
                                                <p class="check-text">Hours (per day)</p>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="o-flex gap-10">
                                        <div class="input-box" v-if="hours === 1">
                                            <p class="u-font-400">Total Hours</p>
                                            <InputNumber :placeholder="'Enter'" :hour="totalHours" @number="setTotalHours"  :max="4"  class="input input--primary"/>
                                        </div>
                                        <div class="input-box" v-if="hours === 0">
                                            <p class="u-font-400">Hours (per day)</p>
                                            <InputNumber :placeholder="'Enter'" :hour="hoursPerDay" @number="setHoursPerDay" :max="10" class="input input--primary"/>
                                        </div>
                                    </div>
                                    <div class="u-text-right">
                                        <a href="javascript:;" class="clear" @click="clearHours">Clear</a>
                                    </div>
                                </div>

                            </div>
                            <div class="filter__footer o-flex o-flex--justify-center gap-10">
                                <button @click="dropdownClose" type="button" class="button button--secondary" >Cancel</button>
                                <button @click="applyFilters" type="button" class="button button--primary">Apply</button>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <div class="card mb-">
            <Timeline ref="timeline" :tasks="tasks" minHeight="1000"></Timeline>
        </div>
    </main>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Header from "@/components/Header/Header.vue";
import Timeline from "@/components/Timeline/Timeline.vue";
import VueMultiselect from "vue-multiselect";
import { getTimeline } from "@/api/bookings";
import { getAllProjects, getSkills, getEmployees, getTeams } from '../../service/general';
import InputNumber from '@/components/InputNumber/InputNumber.vue';

import {
    TIMELINE_MODE,
    TIMELINE_INTERVAL,
} from "@/service/Booking";
import {
    createTaskListFromApiData,
    createTaskListByPhaseFromApiData,
} from "@/service/Timeline";
import {
    dateNow,
    datePickerFormatFromString,
    dateFromString,
    getYearStringFromDate,
    getMonthStringFromDate,
    getDayMonthStringFromDate,
    dateAddWeek,
    dateSubWeek,
    dateAddMonth,
    dateSubMonth,
    dateAddYear,
    dateSubYear,
} from "@/service/DateTime";
import { toast } from "vue3-toastify";

const TIMELINE_INTERVAL_YEAR_ZOOM = 25;
const TIMELINE_INTERVAL_MONTH_ZOOM = 20;
const TIMELINE_INTERVAL_WEEK_ZOOM = 15;
const TIMELINE_ZOOM_MAX = 25;
const TIMELINE_ZOOM_MIN = 15;

export default {
    name: 'BookingTimeline',
    components: {
        Header,
        Timeline,
        VueMultiselect,
        InputNumber,
        VueDatePicker,
    },
    data() {
        return {
            mode: TIMELINE_MODE.RESOURCE,
            interval: TIMELINE_INTERVAL.YEAR,
            cursor: dateNow(),
            startDate: dateNow(),
            endDate: dateNow(),
            startDateFilter: null,
            endDateFilter: null,
            timeLabel: 'Time Label',
            dropdown:false,
            tasks: [],
            projects: [],
            filteredPhases: [],
            projectSelected: null,
            phaseSelected: null,
            skill: 1,
            skillSelected: [],
            allSkills: [],
            employees: [],
            allEmployees: [],
            teamSelected: [],
            allTeams: [],
            hours: 1,
            totalHours: null,
            hoursPerDay: null,
            allModes: [
                TIMELINE_MODE.RESOURCE,
                TIMELINE_MODE.PHASE,
            ],
            allIntervals: [
                TIMELINE_INTERVAL.WEEK,
                TIMELINE_INTERVAL.MONTH,
                TIMELINE_INTERVAL.YEAR,
            ],
        }
    },
    computed: {
        activeFilters() {
            return (
                // this.employees.length !== 0 ||
                // this.teamSelected.length !== 0 ||
                this.projectSelected !== null ||
                this.phaseSelected !== null ||
                this.skillSelected.length !== 0 ||
                // this.totalHours !== null ||
                // this.hoursPerDay !== null ||
                this.startDateFilter !== null ||
                this.endDateFilter !== null
            );
        },
        minEndDate() {
            if (!this.startDateFilter) return null;

            const startDateFilter = new Date(this.startDateFilter);
            startDateFilter.setDate(startDateFilter.getDate() + 1);

            return startDateFilter.toISOString().split('T')[0]; // format to 'yyyy-MM-dd'
        },
    },
    watch: {
        mode: {
            handler() {
                this.timelineChange();
            },
        },

        interval: {
            handler(newIntervalValue) {
                if (newIntervalValue === TIMELINE_INTERVAL.WEEK) {
                    this.setTimelineZoom(TIMELINE_INTERVAL_WEEK_ZOOM);
                }

                if (newIntervalValue === TIMELINE_INTERVAL.MONTH) {
                    this.setTimelineZoom(TIMELINE_INTERVAL_MONTH_ZOOM);
                }

                if (newIntervalValue === TIMELINE_INTERVAL.YEAR) {
                    this.setTimelineZoom(TIMELINE_INTERVAL_YEAR_ZOOM);
                }

                this.timelineChange();
            },
        },

        projectSelected(newValue) {
            this.phaseSelected = null;
            this.componentPayload = [];

            if (newValue && newValue.phases) {
                this.filteredPhases = newValue.phases;
            } else {
                this.filteredPhases = [];
            }
        },
    },
    methods: {
        applyFilters() {
            this.loadTimelineData();
            this.dropdownClose();
        },
        setTotalHours(value) {
            this.totalHours = value;
            if (this.totalHours !== null) {
                this.hoursPerDay = null;
            }
        },
        setHoursPerDay(value) {
            this.hoursPerDay = value;
            if (this.hoursPerDay !== null) {
                this.totalHours = null;
            }
        },
        handleDate() {
            this.endDateFilter = null;
        },

        clearProjectPhase() {
            this.projectSelected = null;
            this.phaseSelected = null;
        },
        clearHours() {
            this.totalHours = null;
            this.hoursPerDay = null;
        },
        clearDates() {
            this.startDateFilter = null;
            this.endDateFilter = null;
        },
        clearAll() {
            this.employees = [];
            this.teamSelected = [];
            this.projectSelected = null;
            this.phaseSelected = null;
            this.skillSelected = [];
            this.totalHours = null;
            this.hoursPerDay = null;
            this.startDateFilter = null;
            this.endDateFilter = null;
        },
        dropdownClose() {
            if(this.dropdown) {
                this.dropdown = false
            }
        },
        async getProjects() {
            try {
                const data = await getAllProjects();
                this.projects = data;
                this.loadPage = false;

            }catch (e) {
                toast.error(e.response, {"position": "top-center", autoClose: 2000});
            }
        },
        timelinePrevous() {
            if (this.interval === TIMELINE_INTERVAL.WEEK) {
                this.cursor = dateSubWeek(this.cursor);
            }

            if (this.interval === TIMELINE_INTERVAL.MONTH) {
                this.cursor = dateSubMonth(this.cursor);
            }

            if (this.interval === TIMELINE_INTERVAL.YEAR) {
                this.cursor = dateSubYear(this.cursor);
            }

            this.timelineChange();
        },
        timelineNext() {
            if (this.interval === TIMELINE_INTERVAL.WEEK) {
                this.cursor = dateAddWeek(this.cursor);
            }

            if (this.interval === TIMELINE_INTERVAL.MONTH) {
                this.cursor = dateAddMonth(this.cursor);
            }

            if (this.interval === TIMELINE_INTERVAL.YEAR) {
                this.cursor = dateAddYear(this.cursor);
            }

            this.timelineChange();
        },
        timelineChange() {
            this.loadTimelineData();
        },
        timelineZoomIn() {
            this.setTimelineZoom(this.getTimelineZoom() - 1);
        },
        timelineZoomOut() {
            this.setTimelineZoom(this.getTimelineZoom() + 1);
        },
        async loadTimelineData() {
            const params = {
                mode: this.mode,
                interval: this.interval,
                cursor: datePickerFormatFromString(this.cursor),
            };

            if (this.projectSelected && this.projectSelected.id) {
                params.project_id = this.projectSelected.id;
            }

            if (this.phaseSelected && this.phaseSelected.id) {
                params.phase_id = this.phaseSelected.id;
            }

            if (this.skillSelected && Array.isArray(this.skillSelected)) {
                params.skill_ids = this.skillSelected.map((skillSelected) => skillSelected.id);

                if (this.skill !== undefined && params.skill_ids.length) {
                    params.skill_any = this.skill;
                }
            }

            if (this.teamSelected && Array.isArray(this.teamSelected)) {
                params.team_ids = this.teamSelected.map((teamSelected) => teamSelected.id);
            }

            if (this.employees && Array.isArray(this.employees)) {
                params.resource_ids = this.employees.map((employee) => employee.id);
            }

            if (this.startDateFilter) {
                params.availability_start_date = this.startDateFilter;
            }

            if (this.endDateFilter) {
                params.availability_end_date = this.endDateFilter;
            }

            if (this.totalHours) {
                params.availability_total_hours = this.totalHours;
            }

            if (this.hoursPerDay) {
                params.availability_hours_per_day = this.hoursPerDay;
            }

            try {
                const { data } = await getTimeline(params);
                if (data.mode === TIMELINE_MODE.PHASE) {
                    this.tasks = createTaskListByPhaseFromApiData(data.data, data.start_date, data.end_date);
                } else {
                    this.tasks = createTaskListFromApiData(data.data, data.start_date, data.end_date);
                }

                this.cursor = dateFromString(data.cursor);
                this.startDate = dateFromString(data.start_date);
                this.endDate = dateFromString(data.end_date);
                this.updateTimeLabel();
            } catch (e) {
                toast.error(e.response?.data.message);
            }
        },
        updateTimeLabel() {
            let label = '';

            if (this.interval === TIMELINE_INTERVAL.WEEK) {
                label = getDayMonthStringFromDate(this.startDate) + ' / ' + getDayMonthStringFromDate(this.endDate);
            }

            if (this.interval === TIMELINE_INTERVAL.MONTH) {
                label = getMonthStringFromDate(this.cursor);
            }

            if (this.interval === TIMELINE_INTERVAL.YEAR) {
                label = getYearStringFromDate(this.cursor);
            }

            this.timeLabel = label;
        },
        allEmployeesLabel ({first_name, last_name}) {
            return `${first_name} ${last_name}`
        },
        getTimelineZoom() {
            return this.$refs.timeline.$refs.gantt.options.times.timeZoom;
        },
        setTimelineZoom(zoom) {
            if (zoom > TIMELINE_ZOOM_MAX) {
                zoom = TIMELINE_ZOOM_MAX;
            }

            if (zoom < TIMELINE_ZOOM_MIN) {
                zoom = TIMELINE_ZOOM_MIN;
            }

            this.$refs.timeline.$refs.gantt.options.times.timeZoom = zoom;
        }
    },

    mounted() {
        this.getProjects();
    },
    async created() {
        this.loadTimelineData();
        const [allSkills, allEmployees, allTeams]  = await Promise.all([getSkills(), getEmployees(), getTeams()])
        this.allSkills = allSkills;
        this.allEmployees = allEmployees;
        this.allTeams = allTeams;
        this.setTimelineZoom(TIMELINE_INTERVAL_YEAR_ZOOM);
    },
}
</script>

<style lang="scss" scoped>

    header {
        display: flex;
        gap: 6px;

        .nav__link {
            padding: 0 16px;
            height: 32px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            color: #637083;

            &.exact-active {
                background-color: #fff;
                color: #141C25;
            }
        }
    }

    .filter__dropdown {
        max-height: 80dvh;
        overflow: auto;
    }

    .clear {
        color: #0166FF;
        font-size: 14px;
    }
    .filterTimeline {
        margin: 0 0 12.5px;

        @media (max-width: 480px) {
            flex-direction: column;
            align-items: stretch;
        }

        &__month {
            gap: 12px;
            flex-shrink: 0;

            button {
                flex-shrink: 0;
                display: flex;
                width: auto !important;
            }

            span {
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                color: var(--light-dark);
            }
        }

        &__options {

            @media (max-width: 480px) {
                flex-direction: column;
            }

            > .multiselect {
                width: 160px;

                @media (max-width: 480px) {
                    width: 100%;
                }
            }
        }
    }

    .filter {

        .filter__dropdown {
            width: 469px;

            @media(max-width: 480px) {
                width: 100%;
            }
        }
    }
</style>


