import axios from "axios";

export const getAllContractTypesApi = () => {
    return axios.get(`/api/admin/contract-types/all`)
}

export const getAllPositionsApi = () => {
    return axios.get(`/api/admin/positions/all`)
}
export const getAllSenioritiesApi = () => {
    return axios.get(`/api/admin/seniority-levels/all`)
}

export const getAllSkillsApi = () => {
    return axios.get(`/api/admin/skills/all`)
}

export const getTopSkillsApi = () => {
    return axios.get(`/api/admin/skills/top-level`)
}

export const getAllLocationsApi = () => {
    return axios.get(`/api/admin/locations/all`)
}

export const getAllTeamsApi = () => {
    return axios.get(`/api/admin/teams/all`)
}

export const getAllEmployeesApi = () => {
    return axios.get(`/api/admin/resources/all`)
}

export const getTeamleadsApi = () => {
    return axios.get(`/api/admin/resources/team-leads`)
}

export const getProjectManagersApi = () => {
    return axios.get(`/api/admin/resources/project-managers`)
}

export const getClientsApi = () => {
    return axios.get(`/api/admin/clients/all`)
}

export const getAllProjectsApi = () => {
    return axios.get(`/api/admin/projects/all`)
}
