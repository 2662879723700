<template>
    <div class="o-container">
        <div class="form-wrapper">

            <div class="u-text-center auth-heading">
                <img src="@/assets/img/auth-icon.svg" alt="">
                <h1 class="u-font-600">Welcome back</h1>
                <p>Dive back into your world with a simple sign-in. Your next adventure awaits – let's get started!</p>
            </div>

            <Form ref="login" @submit="onSubmit" :validation-schema="schema">
                <div class="input-box">
                    <p>Email</p>
                    <div class="relative">
                        <Field type="email" name="email" class="input input--primary" :placeholder="'Email'" v-model="email"/>
                        <ErrorMessage class="text-error" name="email"  />
                    </div>
                </div>
                <div class="input-box">
                    <p>Password</p>
                    <div class="relative">
                        <Field type="password" name="password" class="input input--primary" :placeholder="'Password'" v-model="password"/>
                        <ErrorMessage class="text-error" name="password"  />
                    </div>
                </div>

                <div class="u-text-right">
                    <a href="#" class="forgot-pass">Forgot password?</a>
                </div>

                <button type="submit" class="button button--primary w-100" :disabled="working">
                    Log in
                </button>
            </Form>
        </div>
    </div>
</template>
<script>

import { login } from '@/api/auth';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import axios from "axios";
import { toast } from 'vue3-toastify';

export default {
    name:"Login",

    data() {
        return{
            working:false,
            email:null,
            password:null,

            schema: {
                email: 'required|email',
                password: 'required|min:6',
            }
        }
    },
    components: {
        Field,
        Form,
        ErrorMessage
    },
    methods: {

        async onSubmit(_,actions) {

            this.working = true

            try {
                const { data: {token} } = await login(this.email, this.password)
                localStorage.setItem('auth_token', token);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
                actions.resetForm();
                await this.$router.push({name: 'employee'})
            } catch (e) {
                toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
            }
            this.working = false
        },
    },
    created() {
        defineRule('email', email)
        defineRule('min', min)
        defineRule('required', required)
    }
}
</script>
<style lang="scss" scoped>

    .o-container {

        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .form-wrapper {
        background-color: #fff;
        padding: 32px 40px;
        border-radius: 20px;
        width: 520px;
        box-shadow: 0px 14px 50px 0px rgba(21, 28, 36, 0.05);
    }

    .auth-heading {

        img {
            margin-bottom: 16px;
        }

        h1 {
            margin-bottom: 8px;
        }

        p {
            color: #637083;
            margin-bottom: 28px;
        }
    }


    form {

        .button {
            margin-top: 40px;
        }
    }
</style>