<template>
    <main :class="{'h-100': loadPage}">
        <header class="mb">
            <router-link
              active-class="active"
              class="nav__link"
              :to="{name: 'dashboard'}"
            >
                Team Utilization & Cost
            </router-link>

            <router-link
              active-class="active"
              class="nav__link"
              :to="{name: 'booking-timeline'}"
            >
                Booking Timeline
            </router-link>
            <!-- <router-link
              active-class="active"
              class="nav__link"
              :to="{name: 'estimations-dashboard'}"
            >
                Estimations
            </router-link>
            <router-link
              active-class="active"
              class="nav__link"
              :to="{name: 'total-cost'}"
            >
                Total Cost of Unused Resources
            </router-link> -->
        </header>


        <div class="filterTimeline o-flex o-flex--center o-flex--justify o-flex--wrap gap-10">
            <div class="filterTimeline__month o-flex o-flex--center">
                <button @click="timelinePrevous" class="button">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.3333 8.5H4M4 8.5L8 4.5M4 8.5L8 12.5" stroke="#414E62" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <span>{{ timeLabel }}</span>
                <button @click="timelineNext" class="button">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 8.5H12.3333M12.3333 8.5L8.33333 4.5M12.3333 8.5L8.33333 12.5" stroke="#414E62" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
            <div class="filterTimeline__options o-flex o-flex--center gap-10">
                <VueMultiselect
                    v-model="interval"
                    :options="allIntervals"
                    :close-on-select="true"
                    :multiple="false"
                    :searchable="false"
                    :allow-empty="false"
                    :show-labels="false"
                    class="input--tags"
                />
            </div>
        </div>


        <div class="card">
            <div class="card__header card--border o-flex o-flex--center o-flex--justify o-flex--wrap pt-">
                <div class="flag">
                    <div class="flag__icon">
                        <img src="@/assets/img/dashboard-chart.svg" alt="dashboard-chart" />
                    </div>
                    <div class="flag__content">
                        <h2 class="u-font-500 mb0">Your team is currently utilized in <span class="clr-light-blue">{{ utilizedPercentage }}%</span></h2>
                        <p class="mb0">Check who's free in the upcoming weeks</p>
                    </div>
                </div>
                <div class="o-flex o-flex--center o-flex--wrap gap-10">
                    <div class="search-box">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                            d="M17 17L21 21M11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11C19 13.2053 18.1077 15.2022 16.6644 16.6493C15.2161 18.1015 13.213 19 11 19Z"
                            stroke="#637083"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            />
                        </svg>
                        <input
                            type="search"
                            class="input input--primary input--search"
                            placeholder="Search"
                            v-model="searchTerm"
                            @input="debouncedOnSearch"
                        />
                    </div>
                    <div class="filter-wrapper__btns o-flex o-flex--center">
                        <div class="filter filter--project mr-" v-click-outside="dropdownClose">
                            <button @click="dropdown = true" class="button button--secondary" :class="{'active' : activeFilters}">
                                <svg width="20" height="20" class="mr-" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.50004 5.83333V3.5C2.50004 2.94772 2.94775 2.5 3.50004 2.5H16.5001C17.0524 2.5 17.5001 2.94766 17.5001 3.4999L17.5004 5.83333M2.50004 5.83333L7.98416 10.534C8.20581 10.724 8.33337 11.0013 8.33337 11.2933V16.2192C8.33337 16.8698 8.94476 17.3472 9.57591 17.1894L10.9092 16.856C11.3544 16.7447 11.6667 16.3448 11.6667 15.8859V11.2933C11.6667 11.0014 11.7943 10.724 12.0159 10.534L17.5004 5.83333M2.50004 5.83333H17.5004" stroke="#344051" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                Filter
                            </button>
                            <transition name="fade">
                                <div class="filter__dropdown" v-if="dropdown">
                                    <div class="filter__header o-flex o-flex--end o-flex--justify">
                                        <div class="o-flex o-flex--center gap-10">
                                            <img src="../../assets/img/filter-dropdown.svg" alt="">
                                            <h2 class="u-font-500 mb0">Filter</h2>
                                        </div>
                                        <a v-if="activeFilters" href="javascript:;" @click="clearAll" class="clear">Reset All</a>
                                    </div>
                                    <div class="filter__body">

                                        <h3>Skills</h3>
                                        <div class="o-flex o-flex--center mb">
                                            <div class="check-box mr+">
                                                <input id="skills1" v-model="skill" type="radio" name="skills" :value="1">
                                                <label for="skills1">
                                                    <div class="check-icon">
                                                    <svg fill="none" width="16" viewBox="0 0 16 16">
                                                        <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.333 8.667 6 11.333l6.667-6.666"/>
                                                    </svg>
                                                    </div>
                                                    <p class="check-text">Match any</p>
                                                </label>
                                            </div>
                                            <div class="check-box">
                                                <input id="skills2" v-model="skill" type="radio" name="skills" :value="0">
                                                <label for="skills2">
                                                    <div class="check-icon">
                                                    <svg fill="none" width="16" viewBox="0 0 16 16">
                                                        <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.333 8.667 6 11.333l6.667-6.666"/>
                                                    </svg>
                                                    </div>
                                                    <p class="check-text">Match all</p>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="relative">
                                            <VueMultiselect
                                            v-model="skillSelected"
                                            placeholder="Type..."
                                            :options="allSkills"
                                            :taggable="true"
                                            :multiple="true"
                                            label="name"
                                            :close-on-select="true"
                                            class="input--tags mb- w-100"
                                            track-by="name"/>
                                            <div class="u-text-right">
                                                <a href="javascript:;" class="clear" @click="skillSelected = []">Clear</a>
                                            </div>
                                        </div>

                                        <div class="divider divider-1 mt- mb"></div>

                                        <div class="o-flex o-flex--wrap gap-10">
                                            <div class="input-box o-flex--1">
                                                <p>Team</p>
                                                <div class="relative">
                                                    <VueMultiselect
                                                        v-model="teamSelected"
                                                        placeholder="Type..."
                                                        :options="allTeams"
                                                        :taggable="true"
                                                        :multiple="true"
                                                        label="name"
                                                        :close-on-select="true"
                                                        class="input--tags mb- w-100"
                                                        track-by="name"/>
                                                        <div class="u-text-right">
                                                            <a href="javascript:;" class="clear" @click="teamSelected = []">Clear</a>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="filter__footer o-flex o-flex--justify-center gap-10">
                                        <button type="button" class="button button--secondary" @click="dropdownClose">Cancel</button>
                                        <button type="button" :disabled="filterWorking" @click="applyFilter" class="button button--primary">Apply</button>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card__body" :class="{'h-100': loadPage}">
                <div class="o-flex wrapper-box" :class="{'h-100': loadPage}">
                    <div class="h-100 o-flex--1">
                        <NoData v-if="!loadPage && data.length === 0"/>
                        <Loader v-if="loadPage"/>
                        <div v-else>
                            <div v-if="!loadPage && data.length > 0" class="table-responsive">
                                <table class="table" >
                                    <thead>
                                        <tr>
                                            <th width="30%">
                                                <div class="o-flex o-flex--center gap-5">
                                                    Name
                                                    <span class="o-flex o-flex--column o-flex--center">
                                                        <a href="javascript:;" @click="setSort(allSort.NAME)" :class="sort === allSort.NAME ? 'active': ''" class="sort-trigger o-flex o-flex--center">
                                                            <svg height="20" width="24" viewBox="0 0 24 24">
                                                            <path fill="currentColor" d="m17 13.41-4.29-4.24a1 1 0 0 0-1.42 0l-4.24 4.24a1 1 0 0 0 0 1.42 1 1 0 0 0 1.41 0L12 11.29l3.54 3.54a1 1 0 0 0 .7.29 1 1 0 0 0 .71-.29 1 1 0 0 0 .05-1.42Z"/>
                                                            </svg>
                                                        </a>
                                                        <a href="javascript:;" @click="setSort(allSort.NAME_DESC)" :class="sort === allSort.NAME_DESC ? 'active': ''" class="sort-trigger o-flex o-flex--center">
                                                            <svg width="20" viewBox="0 0 24 24">
                                                            <path fill="currentColor" d="M17 9.17a1 1 0 0 0-1.41 0L12 12.71 8.46 9.17a1 1 0 0 0-1.41 0 1 1 0 0 0 0 1.42l4.24 4.24a1 1 0 0 0 1.42 0L17 10.59a1 1 0 0 0 0-1.42Z"/>
                                                            </svg>
                                                        </a>
                                                    </span>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="o-flex o-flex--center gap-5">
                                                    Booked
                                                    <span class="o-flex o-flex--column o-flex--center">
                                                        <a href="javascript:;" @click="setSort(allSort.BOOKED_HOURS)" :class="sort === allSort.BOOKED_HOURS ? 'active': ''" class="sort-trigger o-flex o-flex--center">
                                                            <svg height="20" width="24" viewBox="0 0 24 24">
                                                            <path fill="currentColor" d="m17 13.41-4.29-4.24a1 1 0 0 0-1.42 0l-4.24 4.24a1 1 0 0 0 0 1.42 1 1 0 0 0 1.41 0L12 11.29l3.54 3.54a1 1 0 0 0 .7.29 1 1 0 0 0 .71-.29 1 1 0 0 0 .05-1.42Z"/>
                                                            </svg>
                                                        </a>
                                                        <a href="javascript:;" @click="setSort(allSort.BOOKED_HOURS_DESC)" :class="sort === allSort.BOOKED_HOURS_DESC ? 'active': ''" class="sort-trigger o-flex o-flex--center">
                                                            <svg width="20" viewBox="0 0 24 24">
                                                            <path fill="currentColor" d="M17 9.17a1 1 0 0 0-1.41 0L12 12.71 8.46 9.17a1 1 0 0 0-1.41 0 1 1 0 0 0 0 1.42l4.24 4.24a1 1 0 0 0 1.42 0L17 10.59a1 1 0 0 0 0-1.42Z"/>
                                                            </svg>
                                                        </a>
                                                    </span>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="o-flex o-flex--center gap-5">
                                                    Available
                                                    <span class="o-flex o-flex--column o-flex--center">
                                                        <a href="javascript:;" @click="setSort(allSort.AVAILABLE_HOURS)" :class="sort === allSort.AVAILABLE_HOURS ? 'active': ''" class="sort-trigger o-flex o-flex--center">
                                                            <svg height="20" width="24" viewBox="0 0 24 24">
                                                            <path fill="currentColor" d="m17 13.41-4.29-4.24a1 1 0 0 0-1.42 0l-4.24 4.24a1 1 0 0 0 0 1.42 1 1 0 0 0 1.41 0L12 11.29l3.54 3.54a1 1 0 0 0 .7.29 1 1 0 0 0 .71-.29 1 1 0 0 0 .05-1.42Z"/>
                                                            </svg>
                                                        </a>
                                                        <a href="javascript:;" @click="setSort(allSort.AVAILABLE_HOURS_DESC)" :class="sort === allSort.AVAILABLE_HOURS_DESC ? 'active': ''" class="sort-trigger o-flex o-flex--center">
                                                            <svg width="20" viewBox="0 0 24 24">
                                                            <path fill="currentColor" d="M17 9.17a1 1 0 0 0-1.41 0L12 12.71 8.46 9.17a1 1 0 0 0-1.41 0 1 1 0 0 0 0 1.42l4.24 4.24a1 1 0 0 0 1.42 0L17 10.59a1 1 0 0 0 0-1.42Z"/>
                                                            </svg>
                                                        </a>
                                                    </span>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="o-flex o-flex--center gap-5">
                                                    Total
                                                </div>
                                            </th>
                                            <th>
                                                <div class="o-flex o-flex--center gap-5">
                                                    Booked %
                                                    <span class="o-flex o-flex--column o-flex--center">
                                                        <a href="javascript:;" @click="setSort(allSort.UTILIZED_PERCENTAGE)" :class="sort === allSort.UTILIZED_PERCENTAGE ? 'active': ''" class="sort-trigger o-flex o-flex--center">
                                                            <svg height="20" width="24" viewBox="0 0 24 24">
                                                            <path fill="currentColor" d="m17 13.41-4.29-4.24a1 1 0 0 0-1.42 0l-4.24 4.24a1 1 0 0 0 0 1.42 1 1 0 0 0 1.41 0L12 11.29l3.54 3.54a1 1 0 0 0 .7.29 1 1 0 0 0 .71-.29 1 1 0 0 0 .05-1.42Z"/>
                                                            </svg>
                                                        </a>
                                                        <a href="javascript:;" @click="setSort(allSort.UTILIZED_PERCENTAGE_DESC)" :class="sort === allSort.UTILIZED_PERCENTAGE_DESC ? 'active': ''" class="sort-trigger o-flex o-flex--center">
                                                            <svg width="20" viewBox="0 0 24 24">
                                                            <path fill="currentColor" d="M17 9.17a1 1 0 0 0-1.41 0L12 12.71 8.46 9.17a1 1 0 0 0-1.41 0 1 1 0 0 0 0 1.42l4.24 4.24a1 1 0 0 0 1.42 0L17 10.59a1 1 0 0 0 0-1.42Z"/>
                                                            </svg>
                                                        </a>
                                                    </span>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="o-flex o-flex--center gap-5">
                                                    Unused cost
                                                    <span class="o-flex o-flex--column o-flex--center">
                                                        <a href="javascript:;" @click="setSort(allSort.UNUSED_COST)" :class="sort === allSort.UNUSED_COST ? 'active': ''" class="sort-trigger o-flex o-flex--center">
                                                            <svg height="20" width="24" viewBox="0 0 24 24">
                                                            <path fill="currentColor" d="m17 13.41-4.29-4.24a1 1 0 0 0-1.42 0l-4.24 4.24a1 1 0 0 0 0 1.42 1 1 0 0 0 1.41 0L12 11.29l3.54 3.54a1 1 0 0 0 .7.29 1 1 0 0 0 .71-.29 1 1 0 0 0 .05-1.42Z"/>
                                                            </svg>
                                                        </a>
                                                        <a href="javascript:;" @click="setSort(allSort.UNUSED_COST_DESC)" :class="sort === allSort.UNUSED_COST_DESC ? 'active': ''" class="sort-trigger o-flex o-flex--center">
                                                            <svg width="20" viewBox="0 0 24 24">
                                                            <path fill="currentColor" d="M17 9.17a1 1 0 0 0-1.41 0L12 12.71 8.46 9.17a1 1 0 0 0-1.41 0 1 1 0 0 0 0 1.42l4.24 4.24a1 1 0 0 0 1.42 0L17 10.59a1 1 0 0 0 0-1.42Z"/>
                                                            </svg>
                                                        </a>
                                                    </span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in data" :key="item.id">
                                            <td>
                                                <p class="mb0 u-font-500">
                                                    {{ item.first_name }} {{ item.last_name }}
                                                </p>
                                            </td>
                                            <td>
                                                <p class="mb0 u-font-500">
                                                    {{ item.booked_hours }} h
                                                </p>
                                            </td>
                                            <td>
                                                <span class="clr-light-red u-font-500">{{ item.available_hours }} h</span>
                                            </td>
                                            <td>
                                                <span class="clr-light-gray u-font-500">{{ item.total_hours }} h</span>
                                            </td>
                                            <td>
                                                <span class="clr-light-gray u-font-500">{{ item.utilized_percentage }}%</span>
                                            </td>
                                            <td>
                                                <span class="clr-light-red u-font-500">{{ item.unused_cost }} EUR</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card__footer" v-if="!loadPage && data.length > 0">
                <Pagination @page-click="getEmployee" :info="pagination" />
            </div>
        </div>
    </main>
</template>
<script>
import Loader from '@/components/Loader/Loader.vue';
import Pagination from "../../components/Pagination/Pagination.vue";
import VueMultiselect from "vue-multiselect";
import VueApexCharts from 'vue3-apexcharts';
import { toast } from 'vue3-toastify';
import { getPaginationPayload } from '../../service/general';
import { getUtilizationResourceList } from '../../api/dashboard/index';
import NoData from '@/components/NoData/NoData.vue';
import { getSkills, getTeams } from '../../service/general';
import {
    UTILIZATION_INTERVAL,
    UTILIZATION_SORT,
} from "@/service/Dashboard";
import {
    dateNow,
    datePickerFormatFromString,
    dateFromString,
    getYearStringFromDate,
    getMonthStringFromDate,
    getDayMonthStringFromDate,
    dateAddWeek,
    dateSubWeek,
    dateAddMonth,
    dateSubMonth,
    dateAdd3Months,
    dateSub3Months,
    dateAdd6Months,
    dateSub6Months,
    dateAddYear,
    dateSubYear,
} from "@/service/DateTime";

export default {
    name: "TimeUtilization",
    components: {
        Loader,
        VueMultiselect,
        VueApexCharts,
        Pagination,
        NoData,
    },
    data() {
        return {
            loadPage: false,
            pagination: null,
            data: [],
            searchTerm: '',
            filterWorking:false,
            dropdown: false,
            skill: 1,
            allSkills: [],
            skillSelected: [],
            allTeams: [],
            teamSelected: [],
            utilizedPercentage: 0,
            timeLabel: 'Time Label',
            interval: UTILIZATION_INTERVAL.MONTH,
            sort: UTILIZATION_SORT.NAME,
            allSort: UTILIZATION_SORT,
            cursor: dateNow(),
            startDate: dateNow(),
            endDate: dateNow(),
            costUp: true,
            allIntervals: [
                UTILIZATION_INTERVAL.WEEK,
                UTILIZATION_INTERVAL.MONTH,
                UTILIZATION_INTERVAL.MONTH3,
                UTILIZATION_INTERVAL.MONTH6,
                UTILIZATION_INTERVAL.YEAR,
            ],
        }
    },
    watch: {
        interval: {
            handler() {
                this.timelineChange();
            },
        },
    },
    computed: {
        activeFilters() {
            return (
                false
            )
        },
    },
    methods: {
        applyFilter() {
            this.dropdown = false;
            this.getEmployee();
        },
        dropdownClose() {
            if(this.dropdown) {
                this.dropdown = false
            }
        },
        setSort(sort) {
            this.sort = sort;
            this.getEmployee();
        },
        timelinePrevous() {
            if (this.interval === UTILIZATION_INTERVAL.WEEK) {
                this.cursor = dateSubWeek(this.cursor);
            }

            if (this.interval === UTILIZATION_INTERVAL.MONTH) {
                this.cursor = dateSubMonth(this.cursor);
            }

            if (this.interval === UTILIZATION_INTERVAL.MONTH3) {
                this.cursor = dateSub3Months(this.cursor);
            }

            if (this.interval === UTILIZATION_INTERVAL.MONTH6) {
                this.cursor = dateSub6Months(this.cursor);
            }

            if (this.interval === UTILIZATION_INTERVAL.YEAR) {
                this.cursor = dateSubYear(this.cursor);
            }

            this.timelineChange();
        },
        timelineNext() {
            if (this.interval === UTILIZATION_INTERVAL.WEEK) {
                this.cursor = dateAddWeek(this.cursor);
            }

            if (this.interval === UTILIZATION_INTERVAL.MONTH) {
                this.cursor = dateAddMonth(this.cursor);
            }

            if (this.interval === UTILIZATION_INTERVAL.MONTH3) {
                this.cursor = dateAdd3Months(this.cursor);
            }

            if (this.interval === UTILIZATION_INTERVAL.MONTH6) {
                this.cursor = dateAdd6Months(this.cursor);
            }

            if (this.interval === UTILIZATION_INTERVAL.YEAR) {
                this.cursor = dateAddYear(this.cursor);
            }

            this.timelineChange();
        },

        timelineChange() {
            this.getEmployee();
        },
        updateTimeLabel() {
            let label = '';

            if (this.interval === UTILIZATION_INTERVAL.WEEK) {
                label = getDayMonthStringFromDate(this.startDate) + ' / ' + getDayMonthStringFromDate(this.endDate);
            }

            if (this.interval === UTILIZATION_INTERVAL.MONTH) {
                label = getMonthStringFromDate(this.cursor);
            }

            if (this.interval === UTILIZATION_INTERVAL.MONTH3) {
                label = getDayMonthStringFromDate(this.startDate) + ' / ' + getDayMonthStringFromDate(this.endDate);
            }

            if (this.interval === UTILIZATION_INTERVAL.MONTH6) {
                label = getDayMonthStringFromDate(this.startDate) + ' / ' + getDayMonthStringFromDate(this.endDate);
            }

            if (this.interval === UTILIZATION_INTERVAL.YEAR) {
                label = getYearStringFromDate(this.cursor);
            }

            this.timeLabel = label;
        },

        async getEmployee(page = 1) {
            this.filterWorking = true;

            let payload = {
                interval: this.interval,
                cursor: datePickerFormatFromString(this.cursor),
                page,
                order: this.sort,
            };

            if (this.searchTerm) {
                payload.term = this.searchTerm;
            }

            if (this.skillSelected && Array.isArray(this.skillSelected)) {
                payload.skill_ids = this.skillSelected.map((skillSelected) => skillSelected.id);

                if (this.skill !== undefined && payload.skill_ids.length) {
                    payload.skill_any = this.skill;
                }
            }

            if (this.teamSelected && Array.isArray(this.teamSelected)) {
                payload.team_ids = this.teamSelected.map((teamSelected) => teamSelected.id);
            }

            try {
                this.loadPage = true;

                const { data } = await getUtilizationResourceList(payload);
                this.data = data.data;

                this.pagination = getPaginationPayload(data);
                this.loadPage = false;
                this.filterWorking = false;

                this.cursor = dateFromString(data.cursor);
                this.startDate = dateFromString(data.start_date);
                this.endDate = dateFromString(data.end_date);
                this.utilizedPercentage = data.utilized_percentage;
                this.updateTimeLabel();
            } catch (e) {
                this.loadPage = false;
                if (e.response && e.response?.status !== 401) {
                    toast.error(e.response?.data.message ?? e.message, { "position": "top-center", autoClose: 2000 });
                }
            }
        },
        onSearch() {
            this.getEmployee();
        },

        debouncedOnSearch() {
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
            this.onSearch();
            }, 500);
        }
    },
    async created() {
        const [allSkills, allTeams]  = await Promise.all([getSkills(), getTeams()]);
        this.allSkills = allSkills;
        this.allTeams = allTeams;

        this.getEmployee();
    },
}
</script>

<style lang="scss" scoped>
    header {
        display: flex;
        gap: 6px;

        .nav__link {
            padding: 0 16px;
            height: 32px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            color: #637083;

            &.exact-active {
                background-color: #fff;
                color: #141C25;
            }
        }
    }

    .sort-trigger {
        svg {
            color: #000;
        }

        &.active {
            svg {
                color: #0166FF;
            }
        }
    }

    .card {
        margin-bottom: 24px;
    }

    .card__body {
        padding: 24px 0;
    }

    .wrapper-box {
        gap: 75px;

        .table-responsive {
            flex: 1;

            td {
                padding: 20px;
            }
        }
    }

    .filterTimeline {
        margin: 0 0 12.5px;

        @media (max-width: 480px) {
            flex-direction: column;
            align-items: stretch;
        }

        &__month {
            gap: 12px;
            flex-shrink: 0;

            button {
                flex-shrink: 0;
                display: flex;
                width: auto !important;
            }

            span {
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                color: var(--light-dark);
            }
        }

        &__options {

            @media (max-width: 480px) {
                flex-direction: column;
            }

            > .multiselect {
                width: 160px;

                @media (max-width: 480px) {
                    width: 100%;
                }
            }
        }
    }
</style>