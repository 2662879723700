<template>
    <main :class="{'h-100': loadPage}">
        <teleport to="#modals">
            <transition name="fade">
                <Modal
                v-if="openModalDelete"
                class="modal"
                :closeButton="false"
                @close="openModalDelete = false"
                >
                <template #header>
                    <div class="flag">
                    <div class="flag__icon">
                        <SvgIcon :icon="svgIcons.modalPen"
                                    icon-width="48px"
                                    icon-height="48px"/>
                    </div>
                    <div class="flag__content">
                        <h2 class="u-font-500 mb0">Delete Booking</h2>
                        
                    </div>
                    </div>
                </template>
                <template #body>
                    <p class="mb0">Are you sure you want to delete <strong>- {{deleteBookingValue}} -</strong></p>
                </template>
                <template #footer>
                    <div class="o-flex modal-button-wrapper">
                    <a
                        href="#"
                        class="button button--secondary"
                        @click="openModalDelete = false"
                        >Cancel</a
                    >
                    <button type="button" :disabled="workingDelete" @click="deleteBooking" class="button button--primary">Delete</button>
                    </div>
                </template>
                </Modal>
            </transition>
        </teleport>
        <section class="filter-wrapper o-flex o-flex--center o-flex--justify o-flex--row-reverse o-flex--wrap gap-10">
    
            <div class="filter-wrapper__btns o-flex o-flex--center">
                <div class="filter filter--project mr-" v-click-outside="dropdownClose">
                    <button @click="dropdown = true" class="button button--secondary" :class="{'active' : activeFilters}">
                        <svg width="20" height="20" class="mr-" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.50004 5.83333V3.5C2.50004 2.94772 2.94775 2.5 3.50004 2.5H16.5001C17.0524 2.5 17.5001 2.94766 17.5001 3.4999L17.5004 5.83333M2.50004 5.83333L7.98416 10.534C8.20581 10.724 8.33337 11.0013 8.33337 11.2933V16.2192C8.33337 16.8698 8.94476 17.3472 9.57591 17.1894L10.9092 16.856C11.3544 16.7447 11.6667 16.3448 11.6667 15.8859V11.2933C11.6667 11.0014 11.7943 10.724 12.0159 10.534L17.5004 5.83333M2.50004 5.83333H17.5004" stroke="#344051" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Filter
                    </button>
                    <transition name="fade">
                        <div class="filter__dropdown" v-if="dropdown">
                            <div class="filter__header o-flex o-flex--end o-flex--justify">
                                <div class="o-flex o-flex--center gap-10">
                                    <img src="../../assets/img/filter-dropdown.svg" alt="">
                                    <h2 class="u-font-500 mb0">Filter</h2>
                                </div>
                                <a v-if="activeFilters" href="javascript:;" @click="clearAll" class="clear">Reset All</a>
                            </div>
                            <div class="filter__body">
                                <p class="mb-">Projects</p>
                                <div class="relative">
                                    <VueMultiselect
                                    v-model="projectSelected"
                                    placeholder="Type..."
                                    :options="allProjects"
                                    label="name"
                                    :close-on-select="true"
                                    class="input--tags mb-"
                                    track-by="name"/>
                                    <div class="u-text-right">
                                        <a href="javascript:;" class="clear" @click="projectSelected = null">Clear</a>
                                    </div>
                                </div>
                    
                                <div class="divider divider-1 mt- mb-"></div>
                                <p class="mb-">Phase</p>
                                <div class="relative">
                                    <VueMultiselect name="phase" class="mb-" v-model="phaseSelected" placeholder="Select one" :options="filteredPhases" label="name" track-by="name">
                                        <template #noResult>
                                            <span>No results</span>
                                        </template>
                                        <template #noOptions>
                                            <span>No phases</span>
                                        </template>
                                    </VueMultiselect>
                                    <div class="u-text-right">
                                        <a href="javascript:;" class="clear" @click="phaseSelected = null">Clear</a>
                                    </div>
                                </div>
                        
                                <div class="divider divider-1 mt- mb-"></div>
                                <p class="mb-">Project Manager</p>
                                <div class="relative">
                                    <VueMultiselect
                                    v-model="projectManagerSelected"
                                    placeholder="Select..."
                                    :options="formattedProjectManagers"
                                    label="name"
                                    :close-on-select="true"
                                    class="input--tags mb-"
                                    track-by="name"/>
                                    <div class="u-text-right">
                                        <a href="javascript:;" class="clear" @click="projectManagerSelected = null">Clear</a>
                                    </div>
                                </div>
                            </div>
                            <div class="filter__footer o-flex o-flex--justify-center gap-10">
                                <button type="button" class="button button--secondary" @click="dropdownClose">Cancel</button>
                                <button type="button" :disabled="filterWorking" @click="applyFilter" class="button button--primary">Apply</button>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </section>
        <div class="card">
            <div v-if="!loadPage" class="card__header card--border o-flex o-flex--center o-flex--justify o-flex--wrap pt-">
                <div class="flag">
                    <div class="flag__icon">
                        <img src="@/assets/img/dashboard-chart.svg" alt="dashboard-chart" />
                    </div>
                    <div class="flag__content">
                        <h2 class="u-font-500 mb0">Bookings</h2>
                    </div>
                </div>
            </div>
            <div class="card__body" :class="{'h-100': loadPage}">
                <Loader v-if="loadPage"/>
                <div class="table-responsive" v-else>
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Project</th>
                            <th>Phase</th>
                            <th>Owner</th>
                            <th>Resources</th>
                            <th>Skills</th>
                            <th width="10%" class="u-text-center">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in data" :key="item.id">
                                <td>
                                    <div class="o-flex o-flex--center">
                                        <span class="color-box-mark mr-" :style="`background-color: ${item.project?.color}`"></span>
                                        {{item.project?.name}}
                                    </div>
                                </td>
                                <td>
                                    {{ item.phase?.name }}
                                </td>
                                <td>
                                    {{filterProjectManager(item.project?.project_manager_id)}}
                                </td>
                                <td>
                                    <ul class="mb0">
                                        <li v-for="booking in item.bookings" :key="booking.id">
                                              {{ booking.resource?.first_name }}
                                              {{ booking.resource?.last_name }}
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul class="mb0">
                                        <li v-for="booking in item.bookings" :key="booking.id">
                                              {{ booking.skill?.name }}
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul
                                        class="o-flex o-flex--center o-flex--justify-center action-list"
                                    >
                                        <li>
                                            <a href="javascript:;" class="icon-trigger" @click="deleteModalHandler(item.id, item.project?.name)">
                                                <SvgIcon  :icon="svgIcons.delete"
                                                    icon-width="20px"
                                                    icon-height="20px"/>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card__footer" v-if="!loadPage && data.length > 0">
                <Pagination @page-click="getBookings" :info="pagination" />
            </div>
        </div> 
   </main>
</template>
<script>
import Loader from '@/components/Loader/Loader.vue';
import Pagination from "../../components/Pagination/Pagination.vue";
import { getPaginationPayload } from "../../service/general";
import SvgIcon from '@/components/SvgIcons/SvgIcons.vue'
import svgIcons from '@/assets/icons/icons'; 
import Modal from '../../components/Modal/Modal.vue';
import { getBookingsApi, deleteBookingApi } from '../../api/bookings'; 
import { getProjectManagers, getAllProjects } from '../../service/general';
import { toast } from 'vue3-toastify';
import VueMultiselect from 'vue-multiselect'

export default {
    name: "Bookings",
    components: {Loader, SvgIcon, Modal, Pagination, VueMultiselect},
    data() {
        return {
            svgIcons,
            loadPage: true,
            pagination: null,
            data: [],
            allProjectManagers: null,
            allProjects: null,
            openModalDelete: false,
            workingDelete: false,
            deleteBookingValue: '',
            filteredPhases: [],
            bookingId: null,
            filterWorking:false,
            dropdown: false,
            projectSelected: null,
            phaseSelected: null,
            projectManagerSelected: null,
        }
    },

    watch: {
        projectSelected(newValue) {
            if (newValue && newValue.phases) {
                this.filteredPhases = newValue.phases;
                if (!newValue.phases.some(phase => phase.id === this.phaseSelected?.id)) {
                    this.phaseSelected = null;
                }
            } else {
                this.filteredPhases = [];
                this.phaseSelected = null;
            }
        },
    },

    computed: {
        activeFilters() {
            return (
                this.projectManagerSelected !== null ||
                this.projectSelected !== null ||
                this.phaseSelected !== null
            );
        },

        formattedProjectManagers() {
            return this.allProjectManagers.map(manager => ({
                ...manager,
                name: `${manager.first_name} ${manager.last_name}`,
            }));
        },
    },

    methods: {

        applyFilter() {
            this.dropdown = false;

            if (
                this.projectManagerSelected && 
                this.projectSelected && 
                this.phaseSelected) {
                localStorage.removeItem('filter');
            } else {
                const filterData = {
                    projectManager: this.projectManagerSelected || null,
                    project: this.projectSelected || null,
                    phase: this.phaseSelected || null,
                };
                localStorage.setItem('filter', JSON.stringify(filterData));
            }

            this.getBookings();
        },

        clearAll() {  
            this.projectManagerSelected = null;
            this.projectSelected = null;
            this.phaseSelected = null;
            localStorage.removeItem('filter'); 
            this.getBookings()
        },

        dropdownClose() {
            if(this.dropdown) {
                this.dropdown = false
            }
        },

        filterProjectManager(id) {
            const manager = this.allProjectManagers.find((manager) => manager.id === id);
            return manager ? manager.first_name + ' ' + manager.last_name : null;
        },

        deleteModalHandler(id, name) {
            this.deleteBookingValue = name;
            this.bookingId = id;
            this.openModalDelete = true;
        },

        async getBookings(page = 1) {
            this.filterWorking = true;
            const filterData = localStorage.getItem('filter');
            let filter = {};

            try {
                filter = filterData ? JSON.parse(filterData) : {};
            } catch (e) {
                toast.error("Failed to parse filter data:", {"position": "top-center", autoClose: 2000});
                filter = {};
            }

            let payload;

            if (Object.keys(filter).length > 0) { 
                this.projectManagerSelected = filter.projectManager || null; 
                this.projectSelected = filter.project || null;
                this.phaseSelected = filter.phase || null;

                payload = {
                    page: page,
                    project_manager_id: filter?.projectManager?.id,
                    ...(this.projectSelected && this.projectSelected.id ? { project_id: this.projectSelected.id } : {}),
                    ...(this.phaseSelected && this.phaseSelected.id ? { phase_id: this.phaseSelected.id } : {}),
                };
            } else {
                payload = {
                    page: page,
                    ...(this.projectManagerSelected !== null ? { project_manager_id: this.projectManagerSelected.id } : {}),
                    ...(this.projectSelected && this.projectSelected.id ? { project_id: this.projectSelected.id } : {}), 
                    ...(this.phaseSelected && this.phaseSelected.id ? { phase_id: this.phaseSelected.id } : {}), 
                };
            }

            try {
                this.loadPage = true;
                const queryParams = new URLSearchParams();
                Object.keys(payload).forEach(key => {
                    if (Array.isArray(payload[key])) {
        
                        payload[key].forEach(value => {
                            queryParams.append(`${key}[]`, value);
                        });
                    } else if (payload[key] !== undefined && payload[key] !== null) {
                        queryParams.append(key, payload[key]); 
                    }
                });
                const { data } = await getBookingsApi(queryParams.toString());
                this.data = data.data;
                this.pagination = getPaginationPayload(data);
                this.loadPage = false;
                this.filterWorking = false;
            } catch (e) {
                if (e.response?.status !== 404) {
                toast.error(e.response?.data?.message || 'An error occurred', {
                    position: 'top-center',
                    autoClose: 2000,
                });
                }
                return null;
            }
        },

        async deleteBooking() {
            this.workingDelete = true;
            try {
                await deleteBookingApi(this.bookingId);
                toast.success(this.deleteBookingValue + " is deleted successfuly.", {"position": "top-center", autoClose: 2000});
                this.bookingId = '';
                this.openModalDelete = false;
                this.workingDelete = false;
                this.getBookings();
            } catch (e) {
                toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
                this.openModalDelete = false;
                setTimeout(() => {
                this.workingDelete = false;
                }, "3000");
            }
        },
    },

    async created() {
        const [ projectManagers, allProjects ] = await Promise.all([
            getProjectManagers(), getAllProjects(),
        ]);

        this.allProjectManagers = projectManagers
        this.allProjects = allProjects
    },

    mounted() {
        this.getBookings();
    },
}
</script>
<style lang="scss" scoped>
    td {
        vertical-align: top;
    }

    .modal-button-wrapper {
        gap: 16px;

        .button {
            height: 44px;
        }
    }

    .filter--project {

        .filter__dropdown {
            
            @media(max-width: 768px) {
                right: auto;
                left: 0;
            }
        }
    }

    .clear {
        color: #0166FF;
        font-size: 14px;
    }
</style>