<template>
    <main :class="{'h-100': loadPage}">
        <Header></Header>
        <EmployeeForm v-if="data" :payload="data" @loading="loading()" @delete="deleteModal" :working="working" @formData="submitForm"/>
        <teleport to="#modals">
            <transition name="fade">
                <Modal
                v-if="openModalDelete"
                class="modal"
                :closeButton="false"
                @close="openModalDelete = false"
                >
                <template #header>
                    <div class="flag">
                    <div class="flag__icon">
                        <img
                        src="@/assets/img/add-contract-type.svg"
                        alt="add-contract-type"
                        />
                    </div>
                    <div class="flag__content">
                        <h2 class="u-font-500 mb0">Delete Employee</h2>
                    </div>
                    </div>
                </template>
                <template #body>
                    <p class="mb0">Are you sure you want to delete <strong>- {{data.first_name}} {{ data.last_name }} -</strong></p>
                </template>
                <template #footer>
                    <div class="o-flex modal-button-wrapper">
                        <a
                            href="#"
                            class="button button--secondary"
                            @click="openModalDelete = false"
                            >Cancel</a
                        >
                        <button type="button" :disabled="workingDelete" @click="deleteEmployee" class="button button--primary">Delete</button>
                    </div>
                </template>
                </Modal>
            </transition>
        </teleport>
    </main>
</template>
<script>
import Header from '@/components/Header/Header.vue';
import EmployeeForm from './EmployeeForm.vue';
import { toast } from 'vue3-toastify';
import { getEmployeeDetailsApi, updateEmployeeApi, deleteEmployeeApi } from '../../api/employee';
import Modal from '../../components/Modal/Modal.vue';

export default {
    name:"EmployeeViewEdit",
    components: {
        Header,
        EmployeeForm,
        Modal
    }, 

    data() {
        return {
            openModalDelete:false,
            userId: null, 
            loadPage: true,
            data: null,
            working: false,
            workingDelete: false,
        };
    },

    methods: {
        deleteModal() {
            this.openModalDelete = true
        },  
        loading(status) {
            this.loadPage = status 
        },
        async getEmployeeDetails() {
            try {
                const { data } = await getEmployeeDetailsApi(this.userId);
                this.data = data
            } catch (e) {
                if (e.response && e.response?.status !== 401) {
                    toast.error(e.response?.data.message ?? e.message, { "position": "top-center", autoClose: 2000 });
                }
            }
        },
        async submitForm(payload) {
            this.working = true
            try {
                await updateEmployeeApi(this.userId,payload)
                this.working = false
                await this.$router.push({name: 'employee'})
                toast.success('Your employee is successfully edited', {"position": "top-center", autoClose: 2000});

            } catch (e) {
                if (e.response && e.response?.status !== 401) {
                    toast.error(e.response?.data.message ?? e.message, { "position": "top-center", autoClose: 2000 });
                }
                setTimeout(() => {
                    this.working = false;
                }, "3000");
            }
        },
        async deleteEmployee() {
            
            this.workingDelete = true
            try {
                await deleteEmployeeApi(this.userId)
                this.workingDelete = false;
                this.openModalDelete = false;
                await this.$router.push({name: 'employee'})
                toast.success('Your employee is successfully deleted', {"position": "top-center", autoClose: 2000});

            } catch (e) {
                if (e.response && e.response?.status !== 401) {
                    toast.error(e.response?.data.message ?? e.message, { "position": "top-center", autoClose: 2000 });
                    this.openModalDelete = false;
                }
                setTimeout(() => {
                    this.workingDelete = false;
                }, "3000");
            }
        }
    },

    created() {
        this.userId = this.$route.params.id;
        this.getEmployeeDetails();
    },
}
</script>
<style lang="scss" scoped>
.modal-button-wrapper {
  gap: 16px;

  .button {
    height: 44px;
  }
}
</style>