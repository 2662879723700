export const UTILIZATION_INTERVAL = {
  WEEK: 'week',
  MONTH: 'month',
  MONTH3: '3months',
  MONTH6: '6months',
  YEAR: 'year',
};

export const UTILIZATION_SORT = {
  NAME: 'name',
  NAME_DESC: 'name_desc',
  BOOKED_HOURS: 'booked_hours',
  BOOKED_HOURS_DESC: 'booked_hours_desc',
  AVAILABLE_HOURS: 'available_hours',
  AVAILABLE_HOURS_DESC: 'available_hours_desc',
  UTILIZED_PERCENTAGE: 'utilized_percentage',
  UTILIZED_PERCENTAGE_DESC: 'utilized_percentage_desc',
  UNUSED_COST: 'unused_cost',
  UNUSED_COST_DESC: 'unused_cost_desc',
};
